import type { TranslationMap } from "../translations";

export const DRUNKGLISH_TRANSLATIONS: TranslationMap = {
    "name": "Alchoholism",
    "flag": "🍺",

    "msg_rotate": "If aiuou wannanaa play betta do that rotate thing with your dddevicee",
    "msg_loading": "Tryna join the fucking game",
    "msg_err_joining": "ahhshiit it broke",
    "msg_err_finding": "AH WHERED IT GO",
    "msg_spectating": "wathching themmm",
    "msg_enter_team_code": "Put those numbers n stuff",
    "msg_lost_team_connection": "They're gone :sob:",
    "msg_error_joining_team": "Couldnnt joinndemm<br>Prolly full or smhth", // <br> here is an HTML break-line element. DO NOT TOUCH OR MOVE.
    "msg_try_again": "Fuck",
    "msg_warning": "You got told to f off!",
    "msg_warning_msg": "You have been told to f off for: <reason>",
    "msg_temp_ban": "You have been temporarily f'd",
    "msg_temp_ban_msg": "You have been f'd for: <reason>",
    "msg_perma_ban": "LMAO",
    "msg_perma_ban_msg": "Like this matters: <reason>",
    "msg_no_reason": "get fucked.",

    "play_solo": "Lonely mfer",
    "play_duo": "2 is better then none ig",
    "play_squad": "You dont have friends",
    "join_team": "You also dont have friends",
    "msg_locked_tooltip": "Do you REALLY want to play with friends?",
    "rules_and_tutorial": "Read em and weep",
    "news": "No",
    "loadout": "Get drippy",
    "settings": "Dont touch it",
    "fullscreen": "Boom",
    "resume": "Dont hit this",
    "quit": "Hit this",
    "none": "Def something",
    "copy": "alt+f4",
    "copied": "Fuck",

    "health": "bignumber",
    "adrenaline": "COCAINNEEEEE",

    "settings_volume": "loud",
    "settings_keybinds": "Buttons",
    "settings_graphics": "Looks sexy",
    "settings_interface": "In my face :leosmug:",
    "settings_save_load": "alt+f4",
    "settings_mobile": "Who ACTUALLY plays mobile...",
    "settings_require_reload": "* Red star = bad. Fix by pressing alt+f4",
    "settings_performance_warning": "* Nothing to be worried about (fr fr ong 100%) press alt+f4 to fix",

    "settings_master_volume": "Big Loud",
    "settings_sfx_volume": "Small Loud",
    "settings_music_volume": "Smallest Loud",
    "settings_old_menu_music": "Old shit",

    "settings_render_mode": "You spin me",
    "settings_render_resolution": "Right around",
    "settings_render_resolution_auto": "Baby",
    "settings_hires_textures": "Right around",
    "settings_cooler_graphics": "Kenosfuckery",
    "settings_antialias": "Shiny?",
    "settings_movement_smoothing": "smooooooooooooooth",
    "settings_responsive_rotation": "Rotates n stuff",
    "settings_camera_shake": "Shakey",

    "settings_interface_scale": "Size doesnt matter dont touch this",
    "settings_minimap_opacity": "See thru shit",
    "settings_fs_map_opacity": "More see thru shit",
    "settings_hide_minimap": "Hide that shit",
    "settings_blur_splash": "Glasses?",
    "settings_hide_rules": "I dont need the rules",
    "settings_warn_before_leaving": "Tell me why",
    "settings_show_fps": "Aint nothing but a heart break",
    "settings_show_ping": "Aint nothing but a mistake",
    "settings_show_coordinates": "Show places",
    "settings_anon_names": "Make names go bye bye",
    "settings_hide_emotes": "Weak",
    "settings_text_killfeed": "Nuh uh",
    "settings_colored_weapon_slots": "Color my slots",
    "settings_scope_looping": "Loopy like my lsd",
    "settings_draw_hud": "HEADS UP",
    "settings_autopickup": "Do you really wanna be a baby and auto pickup",
    "settings_autopickup_dual_guns": "Dual shit ^",

    "settings_load_settings": "Load em up",
    "settings_copy_settings": "copy em",
    "settings_reset_settings": "Do it you wont",

    "settings_reload": "Reload",
    "settings_mobile_controls": "Enable mobile controls",
    "settings_joystick_size": "Joystick size",
    "settings_joystick_opacity": "Joystick opacity",

    "loadout_skins": "Skins",
    "loadout_emotes": "Emotes",
    "loadout_crosshairs": "Crosshairs",
    "loadout_badges": "Badges",
    "loadout_special": "Special",
    "loadout_crosshairs_default": "System default",
    "loadout_crosshairs_size": "Size:",
    "loadout_crosshairs_color": "Color:",
    "loadout_crosshairs_stroke_size": "Stroke Size:",
    "loadout_crosshairs_stroke_color": "Stroke Color:",

    "emotes_category_People": "People",
    "emotes_category_Text": "Text",
    "emotes_category_Memes": "Memes",
    "emotes_category_Icons": "Icons",
    "emotes_category_Misc": "Misc",

    "featured_youtubr": "Youtube guy",
    "featured_streamr": "Streamr guy",

    "btn_report": "No balls",
    "btn_spectate_kill_leader": "Spectate the actual good player",
    "btn_spectate": "alt+f4",
    "btn_play_again": "Go for a walk",
    "btn_menu": "Go home",

    "msg_waiting_for_leader": "Waiting for you",
    "msg_you_died": "LOL IMAGINE DYING",
    "msg_player_died": "<player> died like a bozo",
    "msg_win": "Look at you, you didnt die yet!",
    "msg_your_rank": "Fuck",

    "msg_kills": "Fuck: <kills>",

    "gas_waiting": "AHHH RED STUFF COMING <time>",
    "gas_advancing": "RED STUFF COMING FAST! ALT+F4 TO STOP",
    "gas_inactive": "Waiting for people to join xD",

    "action_open_door": "Open me. You wont",
    "action_close_door": "Thats what i thought",
    "action_revive": "Fuck <player>",
    "action_cancel": "Unfuck",
    "action_reloading": "BADADAN...",
    "action_reviving": "Fucking...",
    "action_being_revived": "Being fucked...",
    "action_gauze_use": "Hahaha <item>",
    "action_medikit_use": "No <item>",
    "action_cola_use": "Snorting <item>",
    "action_tablets_use": "Smoking <item>",

    "interact_airdrop_crate_locked": "Slap that bish",
    "interact_control_panel": "Slap that bish",
    "interact_generator": "Slap that bish",
    "interact_button": "SLAP THAT MOTHAFUCKA",

    "loading_spritesheets": "Trying to find my dad <progress>",
    "loading_connecting": "Trying to join lol",
    "loading_joining_game": "ALT+F4",
    "loading_fetching_data": "Showing the servers who's boss...",
    "loading_finding_game": "finding somewhere to cry",

    "keybind_clear_tooltip": "To remove a keybind, press the keybind and then press either <kbd>Escape</kbd> or <kbd>Backspace</kbd>.",
    "keybind_reset": "Reset to defaults",
    "bindings_+up": "Move North",
    "bindings_+down": "Move South",
    "bindings_+left": "Move That way",
    "bindings_+right": "Move This way",
    "bindings_interact": "Finger",
    "bindings_loot": "SpecialStuff",
    "bindings_slot 0": "Equip Main chick",
    "bindings_slot 1": "Equip Side chick",
    "bindings_slot 2": "Equip Side side chick",
    "bindings_equip_or_cycle_throwables 1": "Gren circle go boom",
    "bindings_last_item": "Equip last chick",
    "bindings_other_weapon": "Equip Other chick",
    "bindings_swap_gun_slots": "Do smth to ur guns :flooshed:",
    "bindings_cycle_items -1": "Hmmmm",
    "bindings_cycle_items 1": "HMMMMMMMMM",
    "bindings_+attack": "Dont use it",
    "bindings_drop": "Dont drop it",
    "bindings_reload": "Quit",
    "bindings_cycle_scopes -1": "Never",
    "bindings_cycle_scopes 1": "Gonna",
    "bindings_use_consumable gauze": "Give",
    "bindings_use_consumable medikit": "You",
    "bindings_use_consumable cola": "Up",
    "bindings_use_consumable tablets": "Never",
    "bindings_cancel_action": "Gonna",
    "bindings_+view_map": "Ey look X marks the spot",
    "bindings_toggle_map": "BIG MAP WTF",
    "bindings_toggle_minimap": "Toggle that tiny shit",
    "bindings_toggle_hud": "Toggle HUD",
    "bindings_+emote_wheel": "Emote Wheel",
    "bindings_+map_ping_wheel": "Switch to Map Ping",
    "bindings_+map_ping": "Map Ping Wheel",
    "bindings_toggle_console": "Console? I barely know her",
    "bindings_toggle_slot_lock": "Why tf would u lock ts?",

    "kf_suicide_kill": "<player> made the right choice",
    "kf_suicide_down": "<player> talked about fight club",
    "kf_two_party_kill": "<player> did horrible things to <victim>",
    "kf_two_party_down": "<player> jerked off <victim>",
    "kf_bleed_out_kill": "<player> L BOZO",
    "kf_bleed_out_down": "<player> how tf did you manage that",
    "kf_finished_off_kill": "<player> did smth <victim>",
    "kf_finished_off_down": "<player> \"gently\" my ass he jerked him off. <victim>",
    "kf_finally_died": "<player> took you long enough",
    "kf_finally_ended_themselves": "<player> good choice",
    "kf_finally_killed": "<player> finally",
    "kf_finally_down": "<player> finally again lol",
    "kf_gas_kill": "<player> L BOZO IMAGINE DYING TO THE GAS",
    "kf_gas_down": "<player> HOW LMFAOOOO",
    "kf_airdrop_kill": "<player> YOU FUCKING IDIOT 🤣",
    "kf_airdrop_down": "<player> dumbass 🤣",
    "kf_kl_promotion": "<player> is the best hitman of the year!",
    "kf_kl_killed": "<player> NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO",
    "kf_kl_dead": "🤣!",
    "kf_kl_suicide": "LMAO HOW DID YOU KILL YOURSELF!",

    "tt_restores": "<item> does some shit <amount> <type>",
    "tt_reduces": "<item> does some shit <percent>% damage",

    "go_kills": "🤣:",
    "go_damage_done": "PainInflicted:",
    "go_damage_taken": "Ouchies:",
    "go_time_alive": "Lasted:",

    "create_team": "You dont have enough friends for this button",
    "create_team_autofill": "🤣",
    "create_team_lock": "Lonely fucker",
    "create_team_waiting": "Chilling...",
    "create_team_play": "GET THIS MOVING",

    "report_reporting": "Reporting",
    "report_id": "Report ID:",
    "report_instructions": `
      <p><strong>Please follow the instructions below!</strong> If you don't, your report will be ignored.</p>
      <h4>How to Submit a Report</h4>
      <ol>
        <li>Join the <a href="https://discord.suroi.io">Discord server.</a></li>
        <li>Go to the <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports
            channel.</a></li>
        <li>Read the report guidelines in the pinned post.</li>
        <li>Submit your report as a post.</li>
      </ol>`,

    "languages": "Languages",

    // loot

    "gauze": "Gauze",
    "medikit": "Medikit",
    "cola": "Cola",
    "tablets": "Tablets",

    "basic_vest": "Basic Vest",
    "regular_vest": "Regular Vest",
    "tactical_vest": "Tactical Vest",
    "basic_helmet": "Basic Helmet",
    "regular_helmet": "Regular Helmet",
    "tactical_helmet": "Tactical Helmet",
    "bag": "Bag", // This shouldn't show up in game
    "basic_pack": "Basic Pack",
    "regular_pack": "Regular Pack",
    "tactical_pack": "Tactical Pack",

    "1x_scope": "1x Scope", // This shouldn't show up in game
    "2x_scope": "2x Scope",
    "4x_scope": "4x Scope",
    "8x_scope": "8x Scope",
    "15x_scope": "15x Scope",

    "fists": "Fists",
    "baseball_bat": "Baseball Bat",
    "hatchet": "Hatchet",
    "kbar": "K-bar",
    "maul": "Maul",
    "gas_can": "Gas Can",
    "heap_sword": "HE-AP Sword",
    "steelfang": "Steelfang",
    "ice_pick": "Ice Pick",
    "seax": "Seax",
    "crowbar": "Crowbar",
    "sickle": "Sickle",

    "mosin": "Mosin-Nagant",
    "radio": "Radio",
    "lewis_gun": "Lewis Gun",
    "deathray": "Death Ray",
    "firework_launcher": "Firework Launcher",
    "arena_closer": "Destroyer Of Worlds",
    "revitalizer": "Revitalizer",
    "s_g17": "G17 (Scoped)",
    "vss": "VSS",
    "pp19": "PP-19",
    "vepr12": "Vepr-12",
    "flues": "Flues",
    "m1895": "M1895",
    "ak47": "AK-47",
    "vector": "Vector",
    "mini14": "Mini-14",
    "model_89": "Model 89",
    "negev": "Negev",
    "tango_51": "Tango 51",
    "barrett": "Barrett M95",
    "stoner_63": "Stoner 63",
    "m1_garand": "M1 Garand",

    "frag_grenade": "Frag Grenade",
    "smoke_grenade": "Smoke Grenade",
    "confetti_grenade": "Confetti Grenade",

    // For dual guns
    "dual_template": "Dual <gun>",

    // regions
    "region_dev": "Localhost Server",
    "region_na": "North America",
    "region_eu": "Europe",
    "region_sa": "South America",
    "region_as": "Asia"
};
