import type { TranslationMap } from "../translations";

export const GERMAN_TRANSLATIONS: TranslationMap = {
    "name": "Deutsch",
    "flag": "🇩🇪",

    "msg_rotate": "Für ein besseres Erlebnis, drehe das Gerät in den Querformatmodus.",
    "msg_loading": "Verbindung wird hergestellt",
    "msg_err_joining": "Fehler beim Beitritt zum Spiel.",
    "msg_err_finding": "Fehler beim Finden des Spiels.",
    "msg_try_again": "Bitte versuche es erneut.",
    "msg_warning": "Du wurdest verwarnt!",
    "msg_warning_msg": "Du wurdest von den Moderatoren wegen folgendem Grund verwarnt: <reason>",
    "msg_temp_ban": "Du wurdest vorübergehend gesperrt!",
    "msg_temp_ban_msg": "Du wurdest wegen folgendem Grund gesperrt: <reason>",
    "msg_perma_ban": "Du wurdest dauerhaft gesperrt!",
    "msg_perma_ban_msg": "Du wurdest wegen folgendem Grund gesperrt: <reason>",
    "msg_no_reason": "Kein Grund angegeben.",

    "play_solo": "Solo",
    "play_duo": "Duos",
    "play_squad": "Squads",
    "join_team": "Team",
    "msg_locked_tooltip": "Das Spiel wechselt alle 24 Stunden zwischen Solo und Duos, da nicht genug Spieler für beide Modi verfügbar sind.",
    "rules_and_tutorial": "Regeln & Tutorial",
    "news": "Nachrichten",
    "loadout": "Ausrüstung",
    "settings": "Einstellungen",
    "fullscreen": "Vollbild",
    "resume": "Fortsetzen",
    "quit": "Beenden",
    "none": "Keine",
    "copy": "Kopieren",

    "health": "Gesundheit",
    "adrenaline": "Adrenalin",

    "settings_volume": "Lautstärke",
    "settings_keybinds": "Tastenbelegungen",
    "settings_graphics": "Grafik",
    "settings_interface": "Benutzeroberfläche",
    "settings_save_load": "Speichern/Laden",
    "settings_mobile": "Mobil",
    "settings_require_reload": "* Das Ändern dieser Einstellungen erfordert ein Neuladen der Seite",
    "settings_performance_warning": "* Diese Einstellung kann auf einigen Geräten Probleme verursachen. Deaktiviere sie, wenn du dem Spiel nicht beitreten kannst.",

    "settings_master_volume": "Hauptlautstärke",
    "settings_sfx_volume": "Effekte-Lautstärke",
    "settings_music_volume": "Musiklautstärke",
    "settings_old_menu_music": "Alte Menümusik",

    "settings_render_mode": "Render-Modus",
    "settings_render_resolution": "Render-Auflösung",
    "settings_render_resolution_auto": "Auto",
    "settings_hires_textures": "Hochauflösende Texturen",
    "settings_cooler_graphics": "Coolere Grafik",
    "settings_antialias": "Anti-Aliasing",
    "settings_movement_smoothing": "Bewegungsglättung",
    "settings_responsive_rotation": "Reaktionsschnelle Drehung",
    "settings_camera_shake": "Kamera wackeln",

    "settings_interface_scale": "Schnittstellenskalierung",
    "settings_minimap_opacity": "Minikarten-Deckkraft",
    "settings_fs_map_opacity": "Vollbildkarten-Deckkraft",
    "settings_hide_minimap": "Minikarte ausblenden",
    "settings_blur_splash": "Splashscreen verwischen",
    "settings_hide_rules": "Regeln-Schaltfläche ausblenden",
    "settings_warn_before_leaving": "Vor dem Verlassen warnen",
    "settings_show_fps": "FPS anzeigen",
    "settings_show_ping": "Ping anzeigen",
    "settings_show_coordinates": "Koordinaten anzeigen",
    "settings_anon_names": "Anonyme Spielernamen",
    "settings_hide_emotes": "Emotes ausblenden",
    "settings_text_killfeed": "Textbasierter Killfeed",
    "settings_colored_weapon_slots": "Farbige Waffenslots",
    "settings_scope_looping": "Zielfernrohr-Schleife",
    "settings_draw_hud": "HUD anzeigen",
    "settings_autopickup": "Automatische Aufnahme aktivieren",
    "settings_autopickup_dual_guns": "Automatische Aufnahme von Doppelwaffen",

    "settings_load_settings": "Einstellungen laden",
    "settings_copy_settings": "Einstellungen in die Zwischenablage kopieren",
    "settings_reset_settings": "Einstellungen zurücksetzen",

    "settings_reload": "Neu laden",
    "settings_mobile_controls": "Mobile Steuerung aktivieren",
    "settings_joystick_size": "Joystick-Größe",
    "settings_joystick_opacity": "Joystick-Deckkraft",

    "loadout_skins": "Skins",
    "loadout_emotes": "Emotes",
    "loadout_crosshairs": "Fadenkreuze",
    "loadout_badges": "Abzeichen",
    "loadout_special": "Spezial",
    "loadout_crosshairs_default": "Systemstandard",
    "loadout_crosshairs_size": "Größe:",
    "loadout_crosshairs_color": "Farbe:",
    "loadout_crosshairs_stroke_size": "Strichstärke:",
    "loadout_crosshairs_stroke_color": "Strichfarbe:",

    "emotes_category_People": "Personen",
    "emotes_category_Text": "Text",
    "emotes_category_Memes": "Memes",
    "emotes_category_Icons": "Symbole",
    "emotes_category_Misc": "Verschiedenes",

    "featured_youtubr": "Vorgestellter Youtubr",
    "featured_streamr": "Vorgestellter Streamr",

    "btn_report": "Melden",
    "btn_spectate_kill_leader": "Anführer zuschauen",
    "btn_spectate": "Zuschauen",
    "btn_play_again": "Nochmal spielen",
    "btn_menu": "Menü",

    "msg_waiting_for_leader": "Warten auf Anführer",
    "msg_you_died": "Du bist gestorben.",
    "msg_player_died": "<player> ist gestorben.",
    "msg_win": "Winner Winner Hähnchen-Dinner!",
    "msg_your_rank": "Rang",

    "msg_kills": "Kills: <kills>",

    "gas_waiting": "Giftgas schreitet voran in <time>",
    "gas_advancing": "Giftgas schreitet voran! Bewege dich zur sicheren Zone",
    "gas_inactive": "Warten auf Spieler...",

    "action_open_door": "Tür öffnen",
    "action_close_door": "Tür schließen",
    "action_revive": "Reviviere <player>",
    "action_cancel": "Abbrechen",
    "action_reloading": "Nachladen...",
    "action_reviving": "Wiederbeleben...",
    "action_being_revived": "Wird wiederbelebt...",
    "action_gauze_use": "<item> verwenden",
    "action_medikit_use": "<item> verwenden",
    "action_cola_use": "<item> trinken",
    "action_tablets_use": "<item> einnehmen",

    "interact_airdrop_crate_locked": "Luftabwurf öffnen",
    "interact_control_panel": "Bedienfeld aktivieren",
    "interact_generator": "Generator aktivieren",
    "interact_button": "Knopf drücken",

    "loading_spritesheets": "Lade Spritesheets <progress>",
    "loading_connecting": "Verbindung wird hergestellt",
    "loading_joining_game": "Spiel beitreten",
    "loading_fetching_data": "Serverdaten werden geladen...",
    "loading_finding_game": "Spiel wird gesucht",

    "keybind_clear_tooltip": "Um eine Tastenbelegung zu entfernen, drücke die Belegung und dann entweder <kbd>Escape</kbd> oder <kbd>Backspace</kbd>.",
    "keybind_reset": "Auf Standard zurücksetzen",
    "bindings_+up": "Nach oben bewegen",
    "bindings_+down": "Nach unten bewegen",
    "bindings_+left": "Nach links bewegen",
    "bindings_+right": "Nach rechts bewegen",
    "bindings_interact": "Interagieren",
    "bindings_loot": "Plündern",
    "bindings_slot 0": "Primärwaffe ausrüsten",
    "bindings_slot 1": "Sekundärwaffe ausrüsten",
    "bindings_slot 2": "Nahkampfwaffe ausrüsten",
    "bindings_equip_or_cycle_throwables 1": "Wurfgegenstand ausrüsten/wechseln",
    "bindings_last_item": "Letzte Waffe ausrüsten",
    "bindings_other_weapon": "Andere Waffe ausrüsten",
    "bindings_swap_gun_slots": "Waffenplätze tauschen",
    "bindings_cycle_items -1": "Vorherige Waffe ausrüsten",
    "bindings_cycle_items 1": "Nächste Waffe ausrüsten",
    "bindings_+attack": "Waffe benutzen",
    "bindings_drop": "Aktive Waffe abwerfen",
    "bindings_reload": "Nachladen",
    "bindings_cycle_scopes -1": "Vorheriges Zielfernrohr",
    "bindings_cycle_scopes 1": "Nächstes Zielfernrohr",
    "bindings_use_consumable gauze": "Gaze verwenden",
    "bindings_use_consumable medikit": "Medikit verwenden",
    "bindings_use_consumable cola": "Cola trinken",
    "bindings_use_consumable tablets": "Tabletten einnehmen",
    "bindings_cancel_action": "Aktion abbrechen",
    "bindings_+view_map": "Karte anzeigen",
    "bindings_toggle_map": "Vollbildkarte umschalten",
    "bindings_toggle_minimap": "Minikarte umschalten",
    "bindings_toggle_hud": "HUD umschalten",
    "bindings_+emote_wheel": "Emote-Rad",
    "bindings_+map_ping_wheel": "Zu Karten-Ping wechseln",
    "bindings_+map_ping": "Karten-Ping-Rad",
    "bindings_toggle_console": "Konsole umschalten",
    "bindings_toggle_slot_lock": "Slot-Sperre umschalten",

    "kf_suicide_kill": "<player> hat Selbstmord begangen",
    "kf_suicide_down": "<player> hat sich selbst ausgeknockt",
    "kf_two_party_kill": "<player> hat <victim> getötet",
    "kf_two_party_down": "<player> hat <victim> ausgeknockt",
    "kf_bleed_out_kill": "<player> ist verblutet",
    "kf_bleed_out_down": "<player> ist nicht tödlich verblutet",
    "kf_finished_off_kill": "<player> hat <victim> erledigt",
    "kf_finished_off_down": "<player> hat <victim> sanft erledigt",
    "kf_finally_died": "<player> ist schließlich gestorben",
    "kf_finally_ended_themselves": "<player> hat sich schließlich selbst getötet",
    "kf_finally_killed": "<player> wurde schließlich getötet",
    "kf_finally_down": "<player> wurde schließlich ausgeknockt",
    "kf_gas_kill": "<player> starb durch das Gas",
    "kf_gas_down": "<player> wurde durch das Gas ausgeknockt",
    "kf_airdrop_kill": "<player> wurde tödlich von einem Luftabwurf getroffen",
    "kf_airdrop_down": "<player> wurde von einem Luftabwurf ausgeknockt",
    "kf_kl_promotion": "<player> wird zum Anführer befördert!",
    "kf_kl_killed": "<player> hat den Anführer getötet",
    "kf_kl_dead": "Der Anführer ist tot!",
    "kf_kl_suicide": "Der Anführer hat Selbstmord begangen!",

    "tt_restores": "<item> stellt <amount> <type> wieder her",
    "tt_reduces": "<item> reduziert <percent>% Schaden",

    "go_kills": "Kills:",
    "go_damage_done": "Schaden verursacht:",
    "go_damage_taken": "Schaden genommen:",
    "go_time_alive": "Überlebenszeit:",

    "create_team": "Team erstellen",
    "create_team_autofill": "Automatisch füllen",
    "create_team_lock": "Team sperren",
    "create_team_waiting": "Warten...",
    "create_team_play": "Spiel starten",

    "report_reporting": "Meldevorgang",
    "report_id": "Melde-ID:",
    "report_instructions": `
      <p><strong>Bitte folge den untenstehenden Anweisungen!</strong> Wenn nicht, wird dein Bericht ignoriert.</p>
      <h4>Wie man einen Bericht einreicht</h4>
      <ol>
        <li>Tritt dem <a href="https://discord.suroi.io">Discord-Server</a> bei.</li>
        <li>Gehe zum <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports Kanal</a>.</li>
        <li>Lies die Bericht-Richtlinien im angepinnten Beitrag.</li>
        <li>Reiche deinen Bericht als Beitrag ein.</li>
      </ol>`,

    "languages": "Sprachen",

    // loot

    "gauze": "Gaze",
    "medikit": "Medikit",
    "cola": "Cola",
    "tablets": "Tabletten",

    "basic_vest": "Basisweste",
    "regular_vest": "Regelmäßige Weste",
    "tactical_vest": "Taktische Weste",
    "basic_helmet": "Basishelm",
    "regular_helmet": "Regelmäßiger Helm",
    "tactical_helmet": "Taktischer Helm",
    "bag": "Tasche", // This shouldn't show up in game
    "basic_pack": "Basisrucksack",
    "regular_pack": "Regelmäßiger Rucksack",
    "tactical_pack": "Taktischer Rucksack",

    "1x_scope": "1x Zielfernrohr", // This shouldn't show up in game
    "2x_scope": "2x Zielfernrohr",
    "4x_scope": "4x Zielfernrohr",
    "8x_scope": "8x Zielfernrohr",
    "15x_scope": "15x Zielfernrohr",

    "fists": "Fäuste",
    "baseball_bat": "Baseballschläger",
    "hatchet": "Handbeil",
    "kbar": "K-Bar",
    "maul": "Spalthammer",
    "gas_can": "Benzinkanister",
    "heap_sword": "HE-AP Schwert",
    "steelfang": "Stahlzahn",
    "ice_pick": "Eispickel",
    "seax": "Seax",

    "frag_grenade": "Splittergranate",
    "smoke_grenade": "Rauchgranate",
    "confetti_grenade": "Konfetti-Granate",

    "radio": "Radio",
    "deathray": "Todesstrahl",

    // For dual guns
    "dual_template": "Doppel <gun>"
};
