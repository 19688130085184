import type { TranslationMap } from "../translations";

export const HUNGARIAN_TRANSLATIONS: TranslationMap = {
    "name": "Magyar",
    "flag": "🇭🇺",

    "msg_rotate": "A jobb élmény érdekében, forgasd el eszközödet fekvő módba.",
    "msg_loading": "Csatlakozás",
    "msg_err_joining": "Hiba a játékhoz csatlakozáskor.",
    "msg_err_finding": "Hiba a játék kereséskor.",
    "msg_spectating": "Szemlélt játékos:",
    "msg_enter_team_code": "Adj meg egy csapat kódot:",
    "msg_lost_team_connection": "A csapathoz való csatlakozás elveszett.",
    "msg_error_joining_team": "Hiba a csapathoz csatlakozáskor.<br>Lehet hogy nem létezik, vagy már tele van.", // <br> here is an HTML break-line element. DO NOT TOUCH OR MOVE.
    "msg_try_again": "Próbálkozz újra!",
    "msg_warning": "Figyelmeztetve lettél!",
    "msg_warning_msg": "Kaptál egy figyelmeztetést a moderátoroktól emiatt: <reason>",
    "msg_temp_ban": "Ideiglenesen ki lettél tiltva!",
    "msg_temp_ban_msg": "Ki lettél tiltva emiatt: <reason>",
    "msg_perma_ban": "Véglegesen ki lettél tiltva!",
    "msg_perma_ban_msg": "Ki lettél tiltva emiatt: <reason>",
    "msg_no_reason": "Nincs ok megadva.",

    "play_solo": "Játék Egyedül",
    "play_duo": "Játék Ketten",
    "play_squad": "Játék Négyen",
    "join_team": "Csatl. csapat.",
    "msg_locked_tooltip": "A játek 24 óránként váltogat egyedüli, és kettes csapatonkénti mód között, mivel nincs elég játékos mindkét mód számára.",
    "rules_and_tutorial": "Szabályok & Tutorial",
    "news": "Újdonságok",
    "loadout": "Felszerelés",
    "settings": "Beállítások",
    "fullscreen": "Teljes képernyő",
    "resume": "Vissza",
    "quit": "Kilépés",
    "none": "Semmi",
    "copy": "Másolás",
    "copied": "Másolva",

    "health": "élet",
    "adrenaline": "adrenalin",

    "settings_volume": "Hangerő",
    "settings_keybinds": "Bill. kiosztás",
    "settings_graphics": "Grafika",
    "settings_interface": "Interfész",
    "settings_save_load": "Mentés/Betöltés",
    "settings_mobile": "Mobil",
    "settings_require_reload": "* Ezen beállítások megvátoztatása az oldal újratöltését igényli.",
    "settings_performance_warning": "* Ez a beállítás problémákat okozhat bizonyos eszközökön. Tiltsd le, ha nem tudsz játékhoz csatlakozni.",

    "settings_master_volume": "Fő hangerő",
    "settings_sfx_volume": "Hangeffekt hangerő",
    "settings_music_volume": "Zene hangerő",
    "settings_old_menu_music": "Régi menü zene",

    "settings_render_mode": "Render mód",
    "settings_render_resolution": "Render felbontás",
    "settings_render_resolution_auto": "Automatikus",
    "settings_hires_textures": "Magas felbontású textúrák",
    "settings_cooler_graphics": "Menőbb grafika",
    "settings_antialias": "Anti-aliasing",
    "settings_movement_smoothing": "Mozgás simítás",
    "settings_responsive_rotation": "Reszponzív forgás",
    "settings_camera_shake": "Kamera rázkódás",

    "settings_interface_scale": "Interfész méret",
    "settings_minimap_opacity": "Kistérkép átlátszatlanság",
    "settings_fs_map_opacity": "Teljes képerny. térkép átlátszatlanság",
    "settings_hide_minimap": "Kistérkép elrejtése",
    "settings_blur_splash": "Splash screen elhomályosítása",
    "settings_hide_rules": "Szabályok gomb elrejtése",
    "settings_warn_before_leaving": "Figyelmeztetés bezárás előtt",
    "settings_show_fps": "FPS megjelenítése",
    "settings_show_ping": "Ping megjelenítése",
    "settings_show_coordinates": "Koordináták megjelenítése",
    "settings_anon_names": "Játékos nevek anonimizálása",
    "settings_hide_emotes": "Emote-ok elrejtése",
    "settings_text_killfeed": "Szöveg alapú killfeed",
    "settings_colored_weapon_slots": "Színes fegyver slotok",
    "settings_scope_looping": "Scope kiválasztás körbefordulással",
    "settings_draw_hud": "HUD megjelenítése",
    "settings_autopickup": "Autopickup engedélyzése",
    "settings_autopickup_dual_guns": "Autopickup dupla fegyvereken",

    "settings_load_settings": "Beállítások betöltése",
    "settings_copy_settings": "Beállítások másolása vágólapra",
    "settings_reset_settings": "Beállítások visszaállítása",

    "settings_reload": "Újratöltés",
    "settings_mobile_controls": "Mobil vezérlők engedélyezése",
    "settings_joystick_size": "Joystick méret",
    "settings_joystick_opacity": "Joystick átlátszatlanság",

    "loadout_skins": "Kinézetek",
    "loadout_emotes": "Emote-ok",
    "loadout_crosshairs": "Célkeresztek",
    "loadout_badges": "Badge-ek",
    "loadout_special": "Speciális",
    "loadout_crosshairs_default": "Rendszer alapértelmezett",
    "loadout_crosshairs_size": "Méret:",
    "loadout_crosshairs_color": "Szín:",
    "loadout_crosshairs_stroke_size": "Kiemelés Mérete:",
    "loadout_crosshairs_stroke_color": "Kiemelés Színe:",

    "emotes_category_People": "Emberek",
    "emotes_category_Text": "Szöveg",
    "emotes_category_Memes": "Mémek",
    "emotes_category_Icons": "Ikonok",
    "emotes_category_Misc": "Egyéb",

    "featured_youtubr": "Kiemelt Youtubr",
    "featured_streamr": "Kiemelt Streamr",

    "btn_report": "Jelentés",
    "btn_spectate_kill_leader": "Kill Leader szemlélése",
    "btn_spectate": "Szemlélés",
    "btn_play_again": "Játék Újra",
    "btn_menu": "Menü",

    "msg_waiting_for_leader": "Várakozás leader-re",
    "msg_you_died": "Meghaltál.",
    "msg_player_died": "<player> meghalt.",
    "msg_win": "Megnyerted a játékot!",
    "msg_your_rank": "Rang",

    "msg_kills": "Ölések: <kills>",

    "gas_waiting": "Mérgező gáz szűkül <time> múlva",
    "gas_advancing": "Mérgező gáz szűkül! Mozogj a biztonságos zónába",
    "gas_inactive": "Várakozás játékosokra...",

    "action_open_door": "Ajtó nyitása",
    "action_close_door": "Ajtó zárása",
    "action_revive": "<player> újraélesztése",
    "action_cancel": "Megszakít",
    "action_reloading": "Újratöltés...",
    "action_reviving": "Újraélesztés...",
    "action_being_revived": "Újraélesztenek...",
    "action_gauze_use": "<item> alkalmazása",
    "action_medikit_use": "<item> használása",
    "action_cola_use": "<item> ivása",
    "action_tablets_use": "<item> beszedése",

    "interact_airdrop_crate_locked": "Airdrop kinyitása",
    "interact_control_panel": "Kontroll Panel aktiválása",
    "interact_generator": "Generátor aktiválása",
    "interact_button": "Gomb megnyomása",

    "loading_spritesheets": "Spritesheet-ek betöltése <progress>",
    "loading_connecting": "Csatlakozás",
    "loading_joining_game": "Csatlakozás játékhoz",
    "loading_fetching_data": "Szerver adatok lekérése...",
    "loading_finding_game": "Játék keresése",

    "keybind_clear_tooltip": "Billentyű kiosztás eltávolításához nyomd meg a kiosztott billentyűt, majd az <kbd>Escape</kbd>-et vagy a <kbd>Backspace</kbd>-t",
    "keybind_reset": "Visszaáll. az alapért.",
    "bindings_+up": "Mozgás felfelé",
    "bindings_+down": "Mozgás lefelé",
    "bindings_+left": "Mozgás balra",
    "bindings_+right": "Mozgás jobbra",
    "bindings_interact": "Interakció",
    "bindings_loot": "Tárgy-felvétel",
    "bindings_slot 0": "Elsődleges fegyver",
    "bindings_slot 1": "Másodlagos fegyver",
    "bindings_slot 2": "Közelharci fegyver",
    "bindings_equip_or_cycle_throwables 1": "Eldobható kiválaszt./váltogat.",
    "bindings_last_item": "Utoljára használt fegyver",
    "bindings_other_weapon": "Másik fegyver",
    "bindings_swap_gun_slots": "Fegyver csere",
    "bindings_cycle_items -1": "Előző fegyver",
    "bindings_cycle_items 1": "Követkető fegyver",
    "bindings_+attack": "Fegyver használata",
    "bindings_drop": "Aktív fegyver eldobása",
    "bindings_reload": "Újratöltés",
    "bindings_cycle_scopes -1": "Előző scope",
    "bindings_cycle_scopes 1": "Következő scope",
    "bindings_use_consumable gauze": "Kötszer használ.",
    "bindings_use_consumable medikit": "Elsőseg. használ.",
    "bindings_use_consumable cola": "Kóla használ.",
    "bindings_use_consumable tablets": "Tabletta használ.",
    "bindings_cancel_action": "Művelet félbeszak.",
    "bindings_+view_map": "Térkép megnyitása",
    "bindings_toggle_map": "Teljes képernyős térkép vált.",
    "bindings_toggle_minimap": "Kistérkép vált.",
    "bindings_toggle_hud": "HUD vált.",
    "bindings_+emote_wheel": "Emote kerék",
    "bindings_+map_ping_wheel": "Térkép Pingre váltás",
    "bindings_+map_ping": "Térkép Ping kerék",
    "bindings_toggle_console": "Konzol vált.",
    "bindings_toggle_slot_lock": "Slot zárás vált.",

    "kf_suicide_kill": "<player> megölte magát",
    "kf_suicide_down": "<player> kiütötte magát",
    "kf_bleed_out_kill": "<player> kivérzett",
    "kf_bleed_out_down": "<player> kivérzett nem halálosan",
    "kf_finished_off_kill": "<player> kivégezte őt: <victim>",
    "kf_finished_off_down": "<player> finoman kivégezte őt: <victim>",
    "kf_finally_died": "<player> végleg meghalt",
    "kf_finally_ended_themselves": "<player> végleg megölte magát",
    "kf_finally_killed": "<player>-t végleg megölték",
    "kf_finally_down": "<player>-t végleg kiütötték",
    "kf_gas_kill": "<player> meghalt a gáztól",
    "kf_gas_down": "<player> kiütődött a gáztól",
    "kf_airdrop_kill": "<player> végzetesen kilapult egy airdrop által",
    "kf_airdrop_down": "<player> kiütődött egy airdrop által",
    "kf_kl_promotion": "<player>-t Kill Leaderré léptették!",
    "kf_kl_killed": "<player> megölte a Kill Leadert",
    "kf_kl_dead": "A Kill Leader halott!",
    "kf_kl_suicide": "A Kill Leader megölte magát!",

    // ------------------------------------------------------------------
    "finally": "végleg",
    "with": "ezzel:",

    // Kill modal only
    "you": "",
    "yourself": "magadat",
    "km_killed": "megölted őt:",
    "km_knocked": "kiütötted őt:",

    "km_message": "<you><finally> <event> <victim> <with> <weapon>",

    // Killfeed.
    "kf_killed": "megölte őt:",
    "kf_knocked": "kiütötte őt:",
    "kf_finished_off": "kivégezte őt:",
    "themselves": "magát",

    "kf_message": "<player> <finally> <event> <victim> <with> <weapon>",
    // ------------------------------------------------------------------

    "tt_restores": "<item> visszatölt <amount> <type>-t",
    "tt_reduces": "<item> <percent>%-kal csökkenti a sérülést",

    "go_kills": "Ölések:",
    "go_damage_done": "Okozott sebzés:",
    "go_damage_taken": "Kapott sebzés:",
    "go_time_alive": "Túlélt idő:",

    "create_team": "Csapat készítése",
    "create_team_autofill": "Auto. feltöltés",
    "create_team_lock": "Csapat lezárása",
    "create_team_waiting": "Várakozás...",
    "create_team_play": "Játék indítása",

    "report_reporting": "Jelentése:",
    "report_id": "Jelentési azonosító:",
    "report_instructions": `
    <p><strong>Kérlek kövesd az alábbi utasításokat!</strong> Ha nem teszed, a jelentésed figyelmen kívül lesz hagyva.</p>
    <h4>Hogyan kell Beküldeni egy Jelentést</h4>
    <ol>
      <li>Csatlakozz a <a href="https://discord.suroi.io">Discord szerverhez</a>!</li>
      <li>Menj a <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports
          csatornához</a>!</li>
      <li>Olvasd el a jelentési útmutatót a kitűzött posztban!</li>
      <li>Küldd be a jelentésedet posztként!</li>
    </ol>`,

    "languages": "Nyelvek",

    // loot

    "gauze": "Fásli",
    "medikit": "Elsősegély",
    "cola": "Kóla",
    "tablets": "Tabletták",

    "basic_vest": "Alap mellény",
    "regular_vest": "Normál mellény",
    "tactical_vest": "Taktikus mellény",
    "basic_helmet": "Alap sisak",
    "regular_helmet": "Normál sisak",
    "tactical_helmet": "Taktikus sisak",
    "bag": "Zsák", // This shouldn't show up in game
    "basic_pack": "Alap hátizsák",
    "regular_pack": "Normál hátizsák",
    "tactical_pack": "Taktikus hátizsák",

    "1x_scope": "1x Scope", // This shouldn't show up in game
    "2x_scope": "2x Scope",
    "4x_scope": "4x Scope",
    "8x_scope": "8x Scope",
    "15x_scope": "15x Scope",

    "fists": "Kezek",
    "baseball_bat": "Baseball ütő",
    "hatchet": "Balta",
    "kbar": "K-bar",
    "maul": "Kalapács",
    "gas_can": "Benzines kanna",
    "heap_sword": "HE-AP kard",
    "steelfang": "Steelfang",
    "ice_pick": "Jégcsákány",
    "seax": "Seax",
    "sickle": "Sarló",
    "crowbar": "Feszítővas",

    "frag_grenade": "Repeszgránát",
    "smoke_grenade": "Füstgránát",
    "confetti_grenade": "Konfetti Gránát",

    "mosin": "Moszin-Nagant",
    "radio": "Rádió",
    "lewis_gun": "Lewis golyószóró",
    "hp18": "HP-18",
    "acr": "ACR",
    "saf_200": "SAF-200",
    "deathray": "Halálsugár",
    "usas12": "USAS-12",
    "firework_launcher": "Tüzijáték kilövő",
    "arena_closer": "Világok elpusztítója",
    "revitalizer": "Revitalizáló",
    "s_g17": "G17 (távcsővel)",
    "vss": "VSS",
    "aug": "AUG",
    "pp19": "PP-19",
    "vepr12": "Vepr-12",
    "flues": "Flues",
    "cz75a": "CZ-75A",
    "g19": "G19",
    "mp40": "MP40",
    "m1895": "M1895",
    "ak47": "AK-47",
    "vector": "Vector",
    "mini14": "Mini-14",
    "model_37": "Model 37",
    "model_89": "Model 89",
    "negev": "Negev",
    "sr25": "SR-25",
    "tango_51": "Tango 51",
    "barrett": "Barrett M95",
    "stoner_63": "Stoner 63",
    "m1_garand": "M1 Garand",
    "micro_uzi": "Micro Uzi",
    "m3k": "M3K",
    "arx160": "ARX-160",
    "m16a4": "M16A4",
    "mg36": "MG-36",
    "mcx_spear": "MCX Spear",

    // For dual guns
    "dual_template": "Dupla <gun>",

    // regions
    "region_dev": "Helyi szerver",
    "region_na": "Észak-Amerika",
    "region_eu": "Európa",
    "region_sa": "Dél-Amerika",
    "region_as": "Ázsia"
};
