import type { TranslationMap } from "../translations";

export const CHINESE_SIMPLIFIED_TRANSLATIONS: TranslationMap = {
    "name": "中文（简体）",
    "flag": "🇨🇳",

    "msg_rotate": "请将设备旋转至横向来获得更好的游戏体验。",
    "msg_loading": "连接中",
    "msg_err_joining": "加入游戏失败。",
    "msg_err_finding": "查找游戏失败。",
    "msg_spectating": "正在观战",
    "msg_enter_team_code": "输入队伍代码：",
    "msg_lost_team_connection": "与队伍失去连接。",
    "msg_error_joining_team": "加入队伍失败。<br>队伍可能不存在或已满.", // <br>这里是一个HTML换行元素。不要触碰或移动。
    "msg_try_again": "请重试。",
    "msg_warning": "你已被警告！",
    "msg_warning_msg": "你已被管理员警告：<reason>",
    "msg_temp_ban": "你已被暂时封禁！",
    "msg_temp_ban_msg": "你已因此被暂时封禁：<reason>",
    "msg_perma_ban": "你已被永久封禁！",
    "msg_perma_ban_msg": "你已因此被永久封禁：<reason>",
    "msg_no_reason": "未提供原因。",

    "play_solo": "单人游戏",
    "play_duo": "双人游戏",
    "play_squad": "小队游戏",
    "join_team": "加入队伍",
    "msg_locked_tooltip": "因为没有足够的玩家同时进行两种游戏，游戏每天在单人游戏和双人游戏之间切换。",
    "rules_and_tutorial": "规则与教程",
    "news": "新闻",
    "loadout": "装饰",
    "settings": "设置",
    "fullscreen": "全屏",
    "resume": "继续",
    "quit": "退出",
    "none": "无",
    "copy": "复制",
    "copied": "已复制",

    "health": "血量",
    "adrenaline": "肾上腺素",

    "settings_volume": "声音",
    "settings_keybinds": "键位",
    "settings_graphics": "图形",
    "settings_interface": "界面",
    "settings_save_load": "保存/加载",
    "settings_mobile": "触控",
    "settings_require_reload": "* 更改这些设置需要刷新网页",
    "settings_performance_warning": "* 此设置在一些设备上可能会造成问题。若你无法加入游戏，请尝试禁用此设置。",

    "settings_master_volume": "主音量",
    "settings_sfx_volume": "音效音量",
    "settings_music_volume": "音乐音量",
    "settings_old_menu_music": "旧菜单音乐",

    "settings_render_mode": "渲染模式",
    "settings_render_resolution": "渲染分辨率",
    "settings_render_resolution_auto": "自动",
    "settings_hires_textures": "高分辨率纹理",
    "settings_cooler_graphics": "更酷的图形",
    "settings_antialias": "抗锯齿",
    "settings_movement_smoothing": "平滑移动",
    "settings_responsive_rotation": "响应式旋转",
    "settings_camera_shake": "画面抖动",

    "settings_interface_scale": "界面缩放",
    "settings_minimap_opacity": "小地图可见度",
    "settings_fs_map_opacity": "大地图可见度",
    "settings_hide_minimap": "隐藏小地图",
    "settings_blur_splash": "模糊启动画面",
    "settings_hide_rules": "隐藏规则按钮",
    "settings_warn_before_leaving": "离开前警告",
    "settings_show_fps": "显示帧数",
    "settings_show_ping": "显示延迟",
    "settings_show_coordinates": "显示坐标",
    "settings_anon_names": "玩家匿名",
    "settings_hide_emotes": "隐藏表情",
    "settings_text_killfeed": "文本击杀信息",
    "settings_colored_weapon_slots": "武器槽颜色",
    "settings_scope_looping": "循环选择倍镜",
    "settings_draw_hud": "平视显示器",
    "settings_autopickup": "自动拾取",
    "settings_autopickup_dual_guns": "自动拾取双枪",

    "settings_load_settings": "加载设置",
    "settings_copy_settings": "复制设置到剪贴板",
    "settings_reset_settings": "重置设置",

    "settings_reload": "刷新",
    "settings_mobile_controls": "触摸控制",
    "settings_joystick_size": "摇杆大小",
    "settings_joystick_opacity": "摇杆可见度",

    "loadout_skins": "服装",
    "loadout_emotes": "表情",
    "loadout_crosshairs": "准星",
    "loadout_badges": "徽章",
    "loadout_special": "特殊",
    "loadout_crosshairs_default": "系统默认",
    "loadout_crosshairs_size": "大小：",
    "loadout_crosshairs_color": "颜色：",
    "loadout_crosshairs_stroke_size": "描边大小：",
    "loadout_crosshairs_stroke_color": "描边颜色：",

    "emotes_category_People": "人物",
    "emotes_category_Text": "文字",
    "emotes_category_Memes": "模因",
    "emotes_category_Icons": "图标",
    "emotes_category_Misc": "杂项",

    "featured_youtubr": "精选Youtubr",
    "featured_streamr": "精选主播",

    "btn_report": "举报",
    "btn_spectate_kill_leader": "观战击杀领袖",
    "btn_spectate": "观战",
    "btn_play_again": "再玩一局",
    "btn_menu": "菜单",

    "msg_waiting_for_leader": "等待击杀领袖",
    "msg_you_died": "你死了。",
    "msg_player_died": "<player>死了。",
    "msg_win": "大吉大利，今晚吃鸡！",
    "msg_your_rank": "排名",

    "msg_kills": "击杀：<kills>",

    "gas_waiting": "毒气将在<time>后扩散",
    "gas_advancing": "毒气正在扩散！向安全区撤离",
    "gas_inactive": "等待玩家中…",

    "action_open_door": "开门",
    "action_close_door": "关门",
    "action_revive": "救援<player>",
    "action_cancel": "取消",
    "action_reloading": "装填中…",
    "action_reviving": "救援中…",
    "action_being_revived": "正在被救援…",
    "action_gauze_use": "应用<item>中",
    "action_medikit_use": "使用<item>中",
    "action_cola_use": "饮用<item>中",
    "action_tablets_use": "服用<item>中",

    "interact_airdrop_crate_locked": "解锁空投",
    "interact_control_panel": "激活控制台",
    "interact_generator": "启动发电机",
    "interact_button": "按下按钮",

    "loading_spritesheets": "加载图像中<progress>",
    "loading_connecting": "连接中",
    "loading_joining_game": "加入游戏中",
    "loading_fetching_data": "获取服务器数据中…",
    "loading_finding_game": "查找游戏中",

    "keybind_clear_tooltip": "要移除键位，请点击选择该键位然后按下<kbd>Escape键</kbd>或<kbd>Backspace键</kbd>。",
    "keybind_reset": "重置全部",
    "bindings_+up": "向上移动",
    "bindings_+down": "向下移动",
    "bindings_+left": "向左移动",
    "bindings_+right": "向右移动",
    "bindings_interact": "交互",
    "bindings_loot": "拾取",
    "bindings_slot 0": "装备主武器",
    "bindings_slot 1": "装备副武器",
    "bindings_slot 2": "装备近战武器",
    "bindings_equip_or_cycle_throwables 1": "装备/切换投掷物",
    "bindings_last_item": "装备前一武器",
    "bindings_other_weapon": "装备主副武器",
    "bindings_swap_gun_slots": "交换主副武器",
    "bindings_cycle_items -1": "装备上一武器",
    "bindings_cycle_items 1": "装备下一武器",
    "bindings_+attack": "使用武器",
    "bindings_drop": "丢弃武器",
    "bindings_reload": "装填弹药",
    "bindings_cycle_scopes -1": "前一倍镜",
    "bindings_cycle_scopes 1": "后一倍镜",
    "bindings_use_consumable gauze": "使用纱布",
    "bindings_use_consumable medikit": "使用医疗包",
    "bindings_use_consumable cola": "使用可乐",
    "bindings_use_consumable tablets": "使用药片",
    "bindings_cancel_action": "取消动作",
    "bindings_+view_map": "查看地图",
    "bindings_toggle_map": "开关大地图",
    "bindings_toggle_minimap": "开关小地图",
    "bindings_toggle_hud": "开关平视显示器",
    "bindings_+emote_wheel": "表情轮盘",
    "bindings_+map_ping_wheel": "改用地图标记",
    "bindings_+map_ping": "地图标记轮盘",
    "bindings_toggle_console": "开关控制台",
    "bindings_toggle_slot_lock": "锁定武器槽",

    "kf_suicide_kill": "<player>自杀了",
    "kf_suicide_down": "<player>击倒了自己",

    "kf_bleed_out_kill": "<player>失血过多",
    "kf_bleed_out_down": "<player>非致命地失血过多",

    "kf_finished_off_kill": "<player>解决了<victim>",
    "kf_finished_off_down": "<player>温柔地解决了<victim>",

    "kf_finally_died": "<player>最终死了",
    "kf_finally_ended_themselves": "<player>最终结束了自己",

    "kf_finally_killed": "<player>最终被击杀了",
    "kf_finally_down": "<player>最终被击倒了",

    "kf_gas_kill": "<player>死于毒气",
    "kf_gas_down": "<player>被毒气晕倒了",

    "kf_airdrop_kill": "<player>被空投压死了",
    "kf_airdrop_down": "<player>被空投压倒了",

    // ------------------------------------------------------------------
    "finally": "最终",
    "with": "用",

    // 击杀Modal
    "you": "你",
    "yourself": "自己",
    "km_killed": "击杀了",
    "km_knocked": "击倒了",

    "km_message": "<you><finally><with><weapon><event><victim>",

    // 击杀信息
    "kf_killed": "击杀了",
    "kf_knocked": "击倒了",
    "kf_finished_off": "解决了",
    "themselves": "自己",

    "kf_message": "<player><finally><with><weapon><event><victim>",
    // ------------------------------------------------------------------

    // 击杀领袖信息
    "kf_kl_promotion": "<player>晋升为击杀领袖！",
    "kf_kl_killed": "<player>击杀了击杀领袖",
    "kf_kl_dead": "击杀领袖死了！",
    "kf_kl_suicide": "击杀领袖自杀了！",

    "tt_restores": "<item>恢复<amount><type>",
    "tt_reduces": "<item>减少<percent>%受到伤害",

    "go_kills": "击杀：",
    "go_damage_done": "造成伤害：",
    "go_damage_taken": "受到伤害：",
    "go_time_alive": "存活时间：",

    "create_team": "创建队伍",
    "create_team_autofill": "自动填充",
    "create_team_lock": "锁定队伍",
    "create_team_waiting": "等待中…",
    "create_team_play": "开始游戏",

    "report_reporting": "举报",
    "report_id": "举报ID：",
    "report_instructions": `
    <p><strong>请按照以下说明操作！</strong>否则，你的举报将被忽略。</p>
    <h4>如何提交举报</h4>
    <ol>
      <li>加入<a href="https://discord.suroi.io">Discord服务器。</a></li>
      <li>前往<a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheatr-reports
          频道。</a></li>
      <li>阅读置顶帖中的举报方针。</li>
      <li>以帖子的形式提交您的举报。</li>
    </ol>`,

    "languages": "语言",

    // 物品

    "gauze": "纱布",
    "medikit": "医疗包",
    "cola": "可乐",
    "tablets": "药片",

    "basic_vest": "基础护甲",
    "regular_vest": "常规护甲",
    "tactical_vest": "战术护甲",
    "basic_helmet": "基础头盔",
    "regular_helmet": "常规头盔",
    "tactical_helmet": "战术头盔",
    "bag": "袋子", // 这不应该出现在游戏中
    "basic_pack": "基础背包",
    "regular_pack": "常规背包",
    "tactical_pack": "战术背包",

    "1x_scope": "一倍镜", // 这不应该出现在游戏中
    "2x_scope": "二倍镜",
    "4x_scope": "四倍镜",
    "8x_scope": "八倍镜",
    "15x_scope": "十五倍镜",

    "fists": "拳头",
    "baseball_bat": "棒球棒",
    "hatchet": "斧头",
    "kbar": "卡巴刀",
    "maul": "槌子",
    "gas_can": "汽油罐",
    "heap_sword": "HE-AP剑",
    "steelfang": "钢牙刀",
    "ice_pick": "冰镐",
    "seax": "西克斯剑",
    "crowbar": "撬棍",
    "sickle": "镰刀",

    "mosin": "莫辛纳甘",
    "radio": "无线电通话机",
    "lewis_gun": "路易士机枪",
    "hp18": "HP-18",
    "acr": "ACR",
    "saf_200": "SAF-200",
    "deathray": "死亡射线",
    "usas12": "USAS-12",
    "firework_launcher": "烟花火箭筒",
    "arena_closer": "世界毁灭者",
    "revitalizer": "复兴者",
    "s_g17": "格洛克17（倍镜）",
    "vss": "VSS",
    "aug": "AUG",
    "pp19": "PP-19",
    "vepr12": "维普尔12",
    "flues": "弗鲁斯",
    "cz75a": "CZ-75A",
    "g19": "格洛克19",
    "mp40": "MP40",
    "m1895": "1895式",
    "ak47": "AK-47",
    "vector": "维克托",
    "mini14": "迷你14",
    "model_37": "37式",
    "model_89": "89式",
    "negev": "内格夫",
    "sr25": "SR-25",
    "tango_51": "探戈51",
    "barrett": "巴雷特95式",
    "stoner_63": "斯通纳63",
    "m1_garand": "1式加兰德",
    "micro_uzi": "微型乌兹",
    "m3k": "M3K",
    "arx160": "ARX-160",
    "m16a4": "M16A4",
    "mg36": "MG-36",
    "mcx_spear": "MCX Spear",

    "frag_grenade": "破片手榴弹",
    "smoke_grenade": "烟雾弹",
    "confetti_grenade": "彩色纸屑手榴弹",

    // 双持枪械
    "dual_template": "双持<gun>",

    // 地区
    "region_dev": "本地",
    "region_na": "北美",
    "region_eu": "欧洲",
    "region_sa": "南美",
    "region_as": "亚洲"
};
