import type { TranslationMap } from "../translations";

export const FRENCH_TRANSLATIONS: TranslationMap = {
    "name": "Français",
    "flag": "🇫🇷",

    "msg_rotate": "Pour une meilleure expérience, passez en mode paysage.",
    "msg_loading": "Connexion",
    "msg_err_joining": "Erreur rencontrée en rejoignant la partie.",
    "msg_err_finding": "Erreur rencontrée en cherchant la partie.",
    "msg_spectating": "En spectateur",
    "msg_enter_team_code": "Entrez un code d'équipe:",
    "msg_lost_team_connection": "Connexion à l'équipe perdue",
    "msg_error_joining_team": "Erreur en rejoignant l'équipe.<br>Elle n'existe pas ou est déja remplie.", // <br> here is an HTML break-line element. DO NOT TOUCH OR MOVE. I will move the br
    "msg_try_again": "Réessayez",
    "msg_warning": "Vous avez reçu un avertissement!",
    "msg_warning_msg": "Vous avez reçu un avertissement des modérateurs pour : <reason>",
    "msg_temp_ban": "Vous avez été temporairement banni de suroi!",
    "msg_temp_ban_msg": "Vous avez été banni pour : <reason>",
    "msg_perma_ban": "Vous avez été banni!",
    "msg_perma_ban_msg": "Vous avez été banni pour : <reason>",
    "msg_no_reason": "Pas de raison donnée.",

    "play_solo": "Jouer Solo",
    "play_duo": "Jouer Duos",
    "play_squad": "Jouer Squads",
    "join_team": "Rejoindre une équipe",
    "msg_locked_tooltip": "Le jeu alterne entre solos et duos toutes les 24 heures, n'ayant pas assez de joueurs pour les deux.",
    "rules_and_tutorial": "Règles et tutoriel",
    "news": "Nouvelles",
    "loadout": "Équipement",
    "settings": "Paramètres",
    "fullscreen": "Plein écran",
    "resume": "Continuer",
    "quit": "Abandonner",
    "none": "None",
    "copy": "Copier",

    "health": "vie",
    "adrenaline": "adrénaline",

    "settings_volume": "Volume",
    "settings_keybinds": "Contrôles",
    "settings_graphics": "Graphiques",
    "settings_interface": "Interface",
    "settings_save_load": "Savegarder/Charger",
    "settings_mobile": "Mobile",
    "settings_require_reload": "* Changer ces paramètres requiert un rafraichissement de la page",
    "settings_performance_warning": "* Ce paramètre peut causer des problèmes sur certaines machines. Désactivez-le si rejoindre un match est impossible.",

    "settings_master_volume": "Volume principal",
    "settings_sfx_volume": "Volume des effets",
    "settings_music_volume": "Volume de la musique",
    "settings_old_menu_music": "Musique de l'ancien menu",

    "settings_render_mode": "Mode de rendu visuel",
    "settings_render_resolution": "Résolution",
    "settings_render_resolution_auto": "Auto",
    "settings_hires_textures": "Textures haute résolution",
    "settings_cooler_graphics": "Meilleures graphiques",
    "settings_antialias": "Textures lisses",
    "settings_movement_smoothing": "Mouvements fluides",
    "settings_responsive_rotation": "Rotation sensible",
    "settings_camera_shake": "Vibrations de l'écran",

    "settings_interface_scale": "Taille de l'interface",
    "settings_minimap_opacity": "Opacité de la minimap",
    "settings_fs_map_opacity": "Opacité de la map plein écran",
    "settings_hide_minimap": "Cacher la minimap",
    "settings_blur_splash": "Flouter l'écran splash",
    "settings_hide_rules": "Cacher les règles",
    "settings_warn_before_leaving": "Avertir avant abandon",
    "settings_show_fps": "Montrer FPS",
    "settings_show_ping": "Montrer ping",
    "settings_show_coordinates": "Montrer coordonnées",
    "settings_anon_names": "Joueurs anonymes",
    "settings_hide_emotes": "Cacher emotes",
    "settings_text_killfeed": "Killfeed sous forme de texte",
    "settings_colored_weapon_slots": "Slots d'armes en couleur",
    "settings_scope_looping": "Faire boucler la sélection de viseurs",
    "settings_draw_hud": "Montrer le HUD",
    "settings_autopickup": "Récupération automatique",
    "settings_autopickup_dual_guns": "Récupérer les armes doubles automatiquement",

    "settings_load_settings": "Charger des paramètres",
    "settings_copy_settings": "Copier les paramètres",
    "settings_reset_settings": "Réinitialiser les paramètres",

    "settings_reload": "Recharger",
    "settings_mobile_controls": "Activer les contrôles pour mobile",
    "settings_joystick_size": "Taille des joysticks",
    "settings_joystick_opacity": "Opacité des joysticks",

    "loadout_skins": "Skins",
    "loadout_emotes": "Emotes",
    "loadout_crosshairs": "Pointeurs",
    "loadout_badges": "Badges",
    "loadout_special": "Spécial",
    "loadout_crosshairs_default": "Paramètres par défaut",
    "loadout_crosshairs_size": "Taille:",
    "loadout_crosshairs_color": "Couleur:",
    "loadout_crosshairs_stroke_size": "Taille du contour:",
    "loadout_crosshairs_stroke_color": "Couleur du contour:",

    "emotes_category_People": "Faces",
    "emotes_category_Text": "Texte",
    "emotes_category_Memes": "Memes",
    "emotes_category_Icons": "Icones",
    "emotes_category_Misc": "Autres",

    "featured_youtubr": "YouTubr du moment",
    "featured_streamr": "Streamr du moment",

    "btn_report": "Signaler",
    "btn_spectate_kill_leader": "Regarder le Kill Leader",
    "btn_spectate": "Passer en spectateur",
    "btn_play_again": "Rejouer",
    "btn_menu": "Menu",

    "msg_waiting_for_leader": "En attente d'un leader",
    "msg_you_died": "Vous êtes mort",
    "msg_player_died": "<player> est mort.",
    "msg_win": "Winner winner chicken dinner!",
    "msg_your_rank": "Rang",

    "msg_kills": "Kills : <kills>",

    "gas_waiting": "Le gas toxique avance dans <time>",
    "gas_advancing": "Le gas toxique avance! Allez vers la zone sûre.",
    "gas_inactive": "En attente de joueurs...",

    "action_open_door": "Ouvrir la porte",
    "action_close_door": "Fermer la porte",
    "action_revive": "Réanimer <player>",
    "action_cancel": "Annuler",
    "action_reloading": "Rechargeant...",
    "action_reviving": "Réanimant...",
    "action_being_revived": "En train d'être réanimé...",
    "action_gauze_use": "Utilisant <item>",
    "action_medikit_use": "Utilisant <item>",
    "action_cola_use": "Utilisant <item>",
    "action_tablets_use": "Utilisant <item>",

    "interact_airdrop_crate_locked": "Ouvrir l'Airdrop",
    "interact_control_panel": "Activer le panneau de contrôle",
    "interact_generator": "Activer le Générateur",
    "interact_button": "Appuyer sur le bouton",

    "loading_spritesheets": "Chargement des feuilles de sprites <progress>",
    "loading_connecting": "Connexion",
    "loading_joining_game": "Connexion à la partie...",
    "loading_fetching_data": "Récupération des données serveur...",
    "loading_finding_game": "En recherche d'une partie",

    "keybind_clear_tooltip": "Pour supprimer une touche, appuyez dessus puis soit sur <kbd>Escape</kbd> ou <kbd>Backspace</kbd>.",
    "keybind_reset": "Réinitialiser au paramètres par défaut",
    "bindings_+up": "Aller en haut",
    "bindings_+down": "Aller en bas",
    "bindings_+left": "Aller à gauche",
    "bindings_+right": "Aller à droite",
    "bindings_interact": "Interagir",
    "bindings_loot": "Piller",
    "bindings_slot 0": "Équiper l'arme primaire",
    "bindings_slot 1": "Équiper l'arme secondaire",
    "bindings_slot 2": "Équiper l'arme de mêlée",
    "bindings_equip_or_cycle_throwables 1": "Équiper/changer de lançable",
    "bindings_last_item": "Équiper la dernière arme",
    "bindings_other_weapon": "Équiper l'autre arme",
    "bindings_swap_gun_slots": "Échanger les slots d'arme",
    "bindings_cycle_items -1": "Équiper l'arme précédente",
    "bindings_cycle_items 1": "Équiper l'arme suivante",
    "bindings_+attack": "Utiliser l'arme",
    "bindings_drop": "Abandonner l'arme active'",
    "bindings_reload": "Recharger",
    "bindings_cycle_scopes -1": "Viseur précédent",
    "bindings_cycle_scopes 1": "Viseur suivant",
    "bindings_use_consumable gauze": "Utiliser des bandages",
    "bindings_use_consumable medikit": "Utiliser un Medikit",
    "bindings_use_consumable cola": "Utiliser un Cola",
    "bindings_use_consumable tablets": "Utiliser des Tablettes",
    "bindings_cancel_action": "Annuler l'Action",
    "bindings_+view_map": "Voir la Map",
    "bindings_toggle_map": "Activer/Désactiver la Map plein écran",
    "bindings_toggle_minimap": "Activer/Désactiver la Minimap",
    "bindings_toggle_hud": "Activer/Désactiver le HUD",
    "bindings_+emote_wheel": "Emote",
    "bindings_+map_ping_wheel": "Passer en ping de Map",
    "bindings_+map_ping": "Map Ping",
    "bindings_toggle_console": "Activer/Désactiver la Console",
    "bindings_toggle_slot_lock": "Activer/Désactiver l'échange de Slots",

    "kf_suicide_kill": "<player> s'est suicidé",
    "kf_suicide_down": "<player> s'est assommé",

    "kf_bleed_out_kill": "<player> a saigné à mort",
    "kf_bleed_out_down": "<player> a rencontré un bug",

    "kf_finished_off_kill": "<player> a exécuté <victim>",
    "kf_finished_off_down": "<player> a rencontré un bug avec l'aide de <victim>",

    "kf_finally_died": "<player> a expiré",
    "kf_finally_ended_themselves": "<player> s'est self-éxécuté",

    "kf_finally_killed": "<player>  a été exécuté",
    "kf_finally_down": "<player> a rencontré un bug",

    "kf_gas_kill": "<player> a été asphyxié par le gas",
    "kf_gas_down": "<player> a été assommé par le gas",

    "kf_airdrop_kill": "<player> s'est fait aplatir par un airdrop",
    "kf_airdrop_down": "<player> s'est fait assommer par un airdrop",

    // ------------------------------------------------------------------
    "finally": "enfin",
    "with": "avec",

    // Kill modal only
    "you": "Vous",
    "yourself": "vous-même",
    "km_killed": "avez tué",
    "km_knocked": "avez assommé",

    "km_message": "<you> <finally> <event> <victim> <with> <weapon>",

    // Killfeed.
    "kf_killed": "a tué",
    "kf_knocked": "a assommé",
    "kf_finished_off": "a exécuté",
    "themselves": "lui-même",

    "kf_message": "<player> <finally> <event> <victim> <with> <weapon>",
    // ------------------------------------------------------------------

    "tt_restores": "<item> : restore <amount> <type>",
    "tt_reduces": "<item> : nullifie <percent>% des dégâts",

    "go_kills": "Kills:",
    "go_damage_done": "Dégâts faits:",
    "go_damage_taken": "Dégâts pris:",
    "go_time_alive": "Temps vivant:",

    "create_team": "Créer son équipe",
    "create_team_autofill": "Remplissage auto",
    "create_team_lock": "Verrouiller l'équipe",
    "create_team_waiting": "En attente...",
    "create_team_play": "Commencer une partie",

    "report_reporting": "Signalant",
    "report_id": "Report ID:",
    "report_instructions": `
      <p><strong>Merci de suivre les instructions ci-dessous! Sinon, votre signalement sera ignoré.</p>
      <h4>Comment signaler</h4>
      <ol>
        <li>Rejoignez le <a href="https://discord.suroi.io">serveur Discord.</a></li>
        <li>Allez dans le salon <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports.</a></li>
        <li>Lisez les règles de signalement dans le post épinglé.</li>
        <li>Envoyez votre signalement sous forme de post.</li>
      </ol>`,

    "languages": "Languages",

    // loot

    "gauze": "Bandages",
    "medikit": "Medikit",
    "cola": "Cola",
    "tablets": "Tablettes",

    "basic_vest": "Veste légère",
    "regular_vest": "Gilet pare-balles",
    "tactical_vest": "Veste tactique",
    "basic_helmet": "Casque normal",
    "regular_helmet": "Casque de combat",
    "tactical_helmet": "Casque tactique",
    "bag": "Sac à dos", // This shouldn't show up in game
    "basic_pack": "Petit sac à dos",
    "regular_pack": "Gros sac à dos",
    "tactical_pack": "Sac à dos tactique",

    "1x_scope": "Viseur 1x", // This shouldn't show up in game
    "2x_scope": "Viseur 2x",
    "4x_scope": "Viseur 4x",
    "8x_scope": "Viseur 8x",
    "15x_scope": "Viseur 15x",

    "fists": "Poings",
    "baseball_bat": "Batte de baseball",
    "hatchet": "Hachette",
    "kbar": "K-bar",
    "maul": "Masse",
    "gas_can": "Jerrycan",
    "heap_sword": "Épée HE-AP",
    "steelfang": "Dent d'argent",
    "ice_pick": "Pic à glace",
    "seax": "Scramasaxe",
    "crowbar": "Pied de Biche",
    "sickle": "Faucille",

    "mosin": "Mosin-Nagant",
    "radio": "Radio",
    "lewis_gun": "Mitraillette Lewis",
    "hp18": "HP-18",
    "acr": "ACR",
    "saf_200": "SAF-200",
    "deathray": "Super Laser",
    "usas12": "USAS-12",
    "firework_launcher": "Lance-Confetti",
    "arena_closer": "Supernova",
    "revitalizer": "Revitalizer",
    "s_g17": "G17 (à lunette)",
    "vss": "VSS",
    "aug": "AUG",
    "pp19": "PP-19",
    "vepr12": "Vepr-12",
    "flues": "Flues",
    "cz75a": "CZ-75A",
    "g19": "G19",
    "mp40": "MP40",
    "m1895": "M1895",
    "ak47": "AK-47",
    "vector": "Vector",
    "mini14": "Mini-14",
    "model_37": "Modèle 37",
    "model_89": "Modèle 89",
    "negev": "Negev",
    "sr25": "SR-25",
    "tango_51": "Tango 51",
    "barrett": "Barrett M95",
    "stoner_63": "Stoner 63",
    "m1_garand": "M1 Garand",
    "micro_uzi": "Micro Uzi",
    "m3k": "M3K",
    "arx160": "ARX-160",
    "m16a4": "M16A4",
    "mg36": "MG-36",
    "mcx_spear": "MCX Spear",

    "frag_grenade": "Grenade à frag",
    "smoke_grenade": "Fumigène",
    "confetti_grenade": "Grenade à confettis",

    // For dual guns
    "dual_template": "Double <gun>",

    // Regions
    "region_dev": "Serveur Localhost",
    "region_na": "Amérique du Nord",
    "region_eu": "Europe",
    "region_sa": "Amérique du Sud",
    "region_as": "Asie"
};
