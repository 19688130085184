import type { TranslationMap } from "../translations";

export const CUTE_ENGWISH_TRANSLATIONS: TranslationMap = {
    "name": "Cute Engwish",
    "flag": "🎀",

    "msg_rotate": "Fow a bettew expewience, pwease wotate your device to wandscape. UwU",
    "msg_loading": "Connectwing >w<",
    "msg_err_joining": "Ewwow joining game. OwO",
    "msg_err_finding": "Ewwow finding game. OwO",
    "msg_try_again": "Pwease twy again. *_*",
    "msg_warning": "You have been wawned owo!",
    "msg_warning_msg": "You hab weceived a wawning by da modewatows fow weason: <reason>",
    "msg_temp_ban": "You hab been tempowawiwy banned!",
    "msg_temp_ban_msg": "You hab been banned fow weason: <reason>",
    "msg_perma_ban": "You hab been pewmanentwy banned! ToT",
    "msg_perma_ban_msg": "You hab been banned fow weason: <reason>",
    "msg_no_reason": "Nu weason pwovided. >o<",

    "play_solo": "Pway sowo",
    "play_duo": "Pway duos",
    "play_squad": "Pway squads",
    "join_team": "Jwoin Team",
    "msg_locked_tooltip": "Da game switches between sowos and duos evewy day, as thewe awen't enough pwayers for both :3",
    "rules_and_tutorial": "Wules & Tutowial",
    "news": "News",
    "loadout": "Lowdowt",
    "settings": "Settwings",
    "fullscreen": "Fullscween",
    "resume": "Wesume",
    "quit": "Quit",
    "none": "None",
    "copy": "Copwy",

    "health": "heawth",
    "adrenaline": "adwenaline",

    "settings_volume": "Vowume",
    "settings_keybinds": "Keybwinds",
    "settings_graphics": "Grafwics",
    "settings_interface": "Intewface",
    "settings_save_load": "Save/Lowd",
    "settings_mobile": "Mobiwle",
    "settings_require_reload": "* Changing dese settwings wequiwes weloading da page. OwO",
    "settings_performance_warning": "* This setting can cawze issues on some dewices. Disabwe if you'we unabwe to join da game. > ~ <",

    "settings_master_volume": "Master vowume",
    "settings_sfx_volume": "SFX vowume",
    "settings_music_volume": "Meowsic vowume",
    "settings_old_menu_music": "Owd menu meowsic",

    "settings_render_mode": "Wendew mode",
    "settings_render_resolution": "Wendew wesowution",
    "settings_render_resolution_auto": "Auto",
    "settings_hires_textures": "High wesowution textuwes",
    "settings_cooler_graphics": "Cuter gwaphics",
    "settings_antialias": "Anti-awiasing",
    "settings_movement_smoothing": "Movement smuwuthing :3",
    "settings_responsive_rotation": "Wesponsive wotation",
    "settings_camera_shake": "Camawa shake",

    "settings_interface_scale": "Intewface scawle",
    "settings_minimap_opacity": "Minimap opwacity",
    "settings_fs_map_opacity": "Fullscween map opwacity",
    "settings_hide_minimap": "Hide minimwap",
    "settings_blur_splash": "Bwur spwash scween",
    "settings_hide_rules": "Hide wules button",
    "settings_warn_before_leaving": "Wawn befowe leavwing",
    "settings_show_fps": "Show Fwames pa second",
    "settings_show_ping": "Show pwing",
    "settings_show_coordinates": "Show coowdinates",
    "settings_anon_names": "Anonymous pwayer names",
    "settings_hide_emotes": "Hide emotes",
    "settings_text_killfeed": "Text-based kwillfweed",
    "settings_colored_weapon_slots": "Colowed weapon swots",
    "settings_scope_looping": "Luwup scwope selection",
    "settings_draw_hud": "Dwaw HUD",
    "settings_autopickup": "Enabwle autopickup",
    "settings_autopickup_dual_guns": "Autopickup duaw guns",

    "settings_load_settings": "Lowd settwings",
    "settings_copy_settings": "Copwy settwings to cwipboawd",
    "settings_reset_settings": "Reset settwings",

    "settings_reload": "Welowd",
    "settings_mobile_controls": "Enabwle mobiwle contwols",
    "settings_joystick_size": "Funstick size",
    "settings_joystick_opacity": "Funstick opwacity",

    "loadout_skins": "Fursuits",
    "loadout_emotes": "Emotes",
    "loadout_crosshairs": "Cwosshairs",
    "loadout_badges": "Badges",
    "loadout_special": "Special",
    "loadout_crosshairs_default": "System defawlt",
    "loadout_crosshairs_size": "Size:",
    "loadout_crosshairs_color": "Colow:",
    "loadout_crosshairs_stroke_size": "Stwoke Size:",
    "loadout_crosshairs_stroke_color": "Stwoke Colow:",

    "emotes_category_People": "Faces",
    "emotes_category_Text": "Twext",
    "emotes_category_Memes": "Memes",
    "emotes_category_Icons": "Icwons",
    "emotes_category_Misc": "Mwisc",

    "featured_youtubr": "Featuwed Youtuba",
    "featured_streamr": "Featuwed Stweama",

    "btn_report": "Wepowt",
    "btn_spectate_kill_leader": "Spectate Kwill Weader",
    "btn_spectate": "Spectate",
    "btn_play_again": "Pway Again",
    "btn_menu": "Menu",

    "msg_waiting_for_leader": "Waiting for weader",
    "msg_you_died": "You died. OwO",
    "msg_player_died": "<player> died. L",
    "msg_win": "Winna Winna Chickwen Dinna!! UwU",
    "msg_your_rank": "Wank",

    "msg_kills": "Kwills: <kills>",

    "gas_waiting": "Toxwic gas advances in <time>",
    "gas_advancing": "Toxwic gas is advancing! Move to da safe zone",
    "gas_inactive": "Waiting for pwayers...",

    "action_open_door": "Opwen Door",
    "action_close_door": "Clowse Door",
    "action_revive": "Hug <player>",
    "action_cancel": "Cancel",
    "action_reloading": "Welowding...",
    "action_reviving": "Hugging...",
    "action_being_revived": "Being hugged >w< ...",
    "action_gauze_use": "Appwying <item>",
    "action_medikit_use": "Using <item>",
    "action_cola_use": "Dwinking <item>",
    "action_tablets_use": "Taking <item>",

    "interact_airdrop_crate_locked": "Opwen Airdwop!!",
    "interact_control_panel": "Activate Contwol Panel!!",
    "interact_generator": "Activate Genewator!!",
    "interact_button": "Pwess Button!!",

    "loading_spritesheets": "Lowding Spwitesheets <progress>",
    "loading_connecting": "Connecting",
    "loading_joining_game": "Joining Game",
    "loading_fetching_data": "Fwetching Serva Data...",
    "loading_finding_game": "Fwinding Game",

    "keybind_clear_tooltip": "To wemove a keybind, pwess da keybind and den pwess either <kbd>Escwape</kbd> or <kbd>Backspwace</kbd>.",
    "keybind_reset": "Weset to defawlts >_<",
    "bindings_+up": "Move Up",
    "bindings_+down": "Move Down",
    "bindings_+left": "Move Left",
    "bindings_+right": "Move Wight",
    "bindings_interact": "Intewact",
    "bindings_loot": "Luwut",
    "bindings_slot 0": "Equwip Pwimawy",
    "bindings_slot 1": "Equwip Secondawy",
    "bindings_slot 2": "Equwip Melee",
    "bindings_equip_or_cycle_throwables 1": "Equwip/Cycle Thwowable",
    "bindings_last_item": "Equwip Last Weapon",
    "bindings_other_weapon": "Equwip Other Gun",
    "bindings_swap_gun_slots": "Swap Gun Swots",
    "bindings_cycle_items -1": "Equwip Pwevious Weapon",
    "bindings_cycle_items 1": "Equwip Next Weapon",
    "bindings_+attack": "Use Weapon",
    "bindings_drop": "Dwop Active Weapon",
    "bindings_reload": "Weload",
    "bindings_cycle_scopes -1": "Pwevious Scwope",
    "bindings_cycle_scopes 1": "Next Scwope",
    "bindings_use_consumable gauze": "Use Gauze",
    "bindings_use_consumable medikit": "Use Medikit",
    "bindings_use_consumable cola": "Use Cowa",
    "bindings_use_consumable tablets": "Use Tabwets",
    "bindings_cancel_action": "Cancel Action",
    "bindings_+view_map": "View Map",
    "bindings_toggle_map": "Toggle Fullscween Map",
    "bindings_toggle_minimap": "Toggle Minimap",
    "bindings_toggle_hud": "Toggle HUD",
    "bindings_+emote_wheel": "Emote Wheel",
    "bindings_+map_ping_wheel": "Switch to Map Pwing",
    "bindings_+map_ping": "Map Pwing Wheel",
    "bindings_toggle_console": "Toggle Console",
    "bindings_toggle_slot_lock": "Toggle Swot Lock",

    "kf_suicide_kill": "<player> died of cwinge OwO",
    "kf_suicide_down": "<player> knocked themselves out",
    "kf_bleed_out_kill": "<player> bwed out",
    "kf_bleed_out_down": "<player bwed out non-lethawwy",
    "kf_finished_off_kill": "<player> fwinished off <victim>",
    "kf_finished_off_down": "<player> gwently fwinished off <victim>",
    "kf_finally_died": "<player> fwinally died",
    "kf_finally_ended_themselves": "<player> fwinally died of cwinge OwO",
    "kf_finally_killed": "<player> was fwinally kwilled",
    "kf_finally_down": "<player> was fwinally knocked out",
    "kf_gas_kill": "<player> died to the gas",
    "kf_gas_down": "<player> was kwnocked out by the gas",
    "kf_airdrop_kill": "<player> was siwently crushed by an airdwop",

    // ------------------------------------------------------------------
    "finally": "fwinally",
    "with": "with",

    // Kill modal only
    "you": "You",
    "yourself": "yourself",
    "km_killed": "kwilled",
    "km_knocked": "kwnocked out",

    "km_message": "<you> <finally> <event> <victim> <with> <weapon>",

    // Killfeed.
    "kf_killed": "kwilled",
    "kf_knocked": "kwnocked out",
    "kf_finished_off": "fwinished off",
    "themselves": "themselves",

    "kf_message": "<player> <finally> <event> <victim> <with> <weapon>",
    // ------------------------------------------------------------------

    "kf_airdrop_down": "<player> was knocked out by an airdwop",
    "kf_kl_promotion": "<player> is pwomoted to the Kwill Weader!",
    "kf_kl_killed": "<player> killed the Kwill Weader",
    "kf_kl_dead": "The Kwill Weader is dead!! >w<",
    "kf_kl_suicide": "The Kwill Weader died of cwinge!",

    "tt_restores": "<item> westores <amount> <type>",
    "tt_reduces": "<item> weduces <percent>% damage",

    "go_kills": "Kwills:",
    "go_damage_done": "Damage Done:",
    "go_damage_taken": "Damage Taken:",
    "go_time_alive": "Time Alive:",

    "create_team": "Cweate Team",
    "create_team_autofill": "Auto Fill",
    "create_team_lock": "Lock Team",
    "create_team_waiting": "Waiting...",
    "create_team_play": "Stawt Game",

    "report_reporting": "Weporting",
    "report_id": "Weport ID:",
    "report_instructions": `
      <p><strong>Pwease follow da instwuctions bewow!</strong> If you dwon't, your report will be ignowed. >_< </p>
      <h4>How to Submit a Weport</h4>
      <ol>
        <li>Join da <a href="https://discord.suroi.io">Discowd server.</a></li>
        <li>Go to da <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheata-wepowts
            channel.</a></li>
        <li>Wead the wepowt guidelines in the pwinned powst.</li>
        <li>Submit your weport as a powst.</li>
      </ol>`,

    "languages": "Wangwages",

    // loot

    "gauze": "Gauze",
    "medikit": "Medikit",
    "cola": "Cowa",
    "tablets": "Tabwets",

    "basic_vest": "Basic Vest",
    "regular_vest": "Reguwa Vest",
    "tactical_vest": "Tactical Vest",
    "basic_helmet": "Basic Hewlmet",
    "regular_helmet": "Reguwa Hewlmet",
    "tactical_helmet": "Tactical Hewlmet",
    "bag": "Bag", // This shouldn't show up in game
    "basic_pack": "Basic Pawk",
    "regular_pack": "Reguwa Pawk",
    "tactical_pack": "Tactical Pawk",

    "1x_scope": "1x Scwope", // This shouldn't show up in game
    "2x_scope": "2x Scwope",
    "4x_scope": "4x Scwope",
    "8x_scope": "8x Scwope",
    "15x_scope": "15x Scwope",

    "fists": "Paws",
    "baseball_bat": "Basebawl Bat",
    "hatchet": "Hatchet",
    "kbar": "K-bar",
    "maul": "Mawl",
    "gas_can": "Gas Can",
    "heap_sword": "HE-AP Swowd",
    "steelfang": "Steewfang",
    "ice_pick": "Ice Pick",
    "seax": "Sex",
    "crowbar": "Cwowbar",
    "sickle": "Sickwle",

    "mosin": "Mosin-Nagant",
    "radio": "Wadio",
    "lewis_gun": "Wewis Gun",
    "deathray": "Deathway",

    "frag_grenade": "Fwag Gwenade",
    "smoke_grenade": "Smoke Gwenade",
    "confetti_grenade": "Confetti Gwenade",

    // For dual guns
    "dual_template": "Dual <gun>",

    // Regions
    "region_dev": "Wocalhost Server",
    "region_na": "Nowth Amewica",
    "region_eu": "Euwope",
    "region_sa": "South Amewica",
    "region_as": "Asia"
};
