import type { TranslationMap } from "../translations";

export const LATVIAN_TRANSLATIONS: TranslationMap = {
    "name": "Latviešu",
    "flag": "🇱🇻",

    "msg_rotate": "Labākai pieredzei, lūdzu, pagrieziet ierīci horizontāli .",
    "msg_loading": "Savienojas",
    "msg_err_joining": "Kļūda pievienojoties spēlei.",
    "msg_err_finding": "Kļūda atrodot spēli.",
    "msg_spectating": "Skatīties spēlētājus",
    "msg_enter_team_code": "Ievadiet komandas kodu:",
    "msg_lost_team_connection": "Zaudēts savienojums ar komandu.",
    "msg_error_joining_team": "Kļūda pievienojoties komandai.<br>Varbūt tā neeksistē vai ir pilna.", // <br> here is an HTML break-line element. DO NOT TOUCH OR MOVE.
    "msg_try_again": "Lūdzu, mēģiniet vēlreiz.",
    "msg_warning": "Jūs esat brīdināts!",
    "msg_warning_msg": "Moderatori jums ir izteikuši brīdinājumu par iemeslu: <reason>",
    "msg_temp_ban": "Jums ir pagaidu liegums!",
    "msg_temp_ban_msg": "Jums ir piemērots aizliegums iemeslu dēļ: <reason>",
    "msg_perma_ban": "Jums ir mūžīgs liegums!",
    "msg_perma_ban_msg": "Jums ir piemērots aizliegums iemeslu dēļ: <reason>",
    "msg_no_reason": "Nav norādīts iemesls.",

    "play_solo": "Spēlēt Solo",
    "play_duo": "Spēlēt Duos",
    "play_squad": "Spēlēt Squads",
    "join_team": "Pievienoties Komandai",
    "msg_locked_tooltip": "Spēle katru dienu maina režīmus starp solo un duo, jo nav pietiekami daudz spēlētāju abiem variantiem.",
    "rules_and_tutorial": "Noteikumi un Pamācība",
    "news": "Jaunumi",
    "loadout": "Ekipējums",
    "settings": "Iestatījumi",
    "fullscreen": "Pilnekrāna režīms",
    "resume": "Atsākt",
    "quit": "Iziet",
    "none": "Nav",
    "copy": "Kopēt",
    "copied": "Nokopēts",

    "health": "veselības",
    "adrenaline": "adrenalīns",

    "settings_volume": "Skaļums",
    "settings_keybinds": "Taustiņu iestatījumi",
    "settings_graphics": "Grafikas",
    "settings_interface": "Saskarsne",
    "settings_save_load": "Saglabāt/Ielādēt",
    "settings_mobile": "Mobilais",
    "settings_require_reload": "* Šo iestatījumu nomaiņa prasa lapas atkārtotu ielādi",
    "settings_performance_warning": "* Šis iestatījums var izraisīt problēmas dažādās ierīcēs. Izslēdziet to, ja nevarat pievienoties spēlei.",

    "settings_master_volume": "Galvenais skaļums",
    "settings_sfx_volume": "SFX skaļums",
    "settings_music_volume": "Mūzikas skaļums",
    "settings_old_menu_music": "Vecās izvēlnes mūzika",

    "settings_render_mode": "Renderēšanas režīms",
    "settings_render_resolution": "Renderēšanas izšķirtspēja",
    "settings_render_resolution_auto": "Automātiski",
    "settings_hires_textures": "Augstas izšķirtspējas tekstūras",
    "settings_cooler_graphics": "Izsmalcināta grafika",
    "settings_antialias": "Antialisings",
    "settings_movement_smoothing": "Kustības izlīdzināšana",
    "settings_responsive_rotation": "Reaģējoša rotācija",
    "settings_camera_shake": "Kameras kratīšanās",

    "settings_interface_scale": "Saskarmes mērogs",
    "settings_minimap_opacity": "Minikartes caurspīdīgums",
    "settings_fs_map_opacity": "Pilnekrāna kartes caurspīdīgums",
    "settings_hide_minimap": "Paslēpt minikarti",
    "settings_blur_splash": "Aizmiglojuma sākuma ekrāns",
    "settings_hide_rules": "Paslēpt noteikumu pogu",
    "settings_warn_before_leaving": "Brīdināt pirms iziešanas",
    "settings_show_fps": "Rādīt FPS",
    "settings_show_ping": "Rādīt ping",
    "settings_show_coordinates": "Rādīt koordinātas",
    "settings_anon_names": "Anonīmi spēlētāju vārdi",
    "settings_hide_emotes": "Paslēpt emocijas",
    "settings_text_killfeed": "killfeeda informācija",
    "settings_colored_weapon_slots": "Krāsains ieroču slots",
    "settings_scope_looping": "Cikla tēmekļa izvēle",
    "settings_draw_hud": "Zīmēt HUD",
    "settings_autopickup": "Ieslēgt automātisko paņemšanu",
    "settings_autopickup_dual_guns": "Automātiska dubulto ieroču paņemšana",

    "settings_load_settings": "Ielādēt iestatījumus",
    "settings_copy_settings": "Kopēt iestatījumus",
    "settings_reset_settings": "Atiestatīt iestatījumus",

    "settings_reload": "Atkārtoti ielādēt",
    "settings_mobile_controls": "Ieslēgt telefona kontroles",
    "settings_joystick_size": "Joysticka izmērs",
    "settings_joystick_opacity": "Joysticka caurspīdīgums",

    "loadout_skins": "Ādas",
    "loadout_emotes": "Emocijas",
    "loadout_crosshairs": "Tēmekļi",
    "loadout_badges": "Medaļas",
    "loadout_special": "Īpašs",
    "loadout_crosshairs_default": "Sistēmas noklusējums",
    "loadout_crosshairs_size": "Izmērs:",
    "loadout_crosshairs_color": "Krāsa:",
    "loadout_crosshairs_stroke_size": "Kontūras izmērs:",
    "loadout_crosshairs_stroke_color": "Kontūras krāsa:",

    "emotes_category_People": "Cilvēki",
    "emotes_category_Text": "Teksts",
    "emotes_category_Memes": "Memes",
    "emotes_category_Icons": "Ikonas",
    "emotes_category_Misc": "Un citi",

    "featured_youtubr": "Piedāvātais Youtubers",
    "featured_streamr": "Piedāvātais Streamers",

    "btn_report": "reportot",
    "btn_spectate_kill_leader": "Skatīties killu līderi",
    "btn_spectate": "Skatīties",
    "btn_play_again": "Spēlēt vēlreiz",
    "btn_menu": "Izvēlne",

    "msg_waiting_for_leader": "Gaida līderi",
    "msg_you_died": "Tu esi miris.",
    "msg_player_died": "<player> ir miris.",
    "msg_win": "Uzvarētājs, uzverātājs, vista vakariņās!",
    "msg_your_rank": "Rangs",

    "msg_kills": "killi: <kills>",

    "gas_waiting": "Toksisks gāze virzās pēc <time>",
    "gas_advancing": "Toksisks gāze virzās! Pārvietojieties uz drošo zonu",
    "gas_inactive": "Gaida spēlētājus...",

    "action_open_door": "Atvērt durvis",
    "action_close_door": "Aizvērt durvis",
    "action_revive": "Atdzīvināt <player>",
    "action_cancel": "Atcelt",
    "action_reloading": "Pārlādē...",
    "action_reviving": "Atdzīvināšana...",
    "action_being_revived": "Tiek atdzīvināts...",
    "action_gauze_use": "Izmantot <item>",
    "action_medikit_use": "Izmantot <item>",
    "action_cola_use": "Izmantot <item>",
    "action_tablets_use": "Izmantot <item>",

    "interact_airdrop_crate_locked": "Atvērt airdropu",
    "interact_control_panel": "Aktivizēt kontroles paneli",
    "interact_generator": "Aktivizēt ģeneratoru",
    "interact_button": "Nospiest pogu",

    "loading_spritesheets": "Ielādē spritesheets <progress>",
    "loading_connecting": "Savienojas",
    "loading_joining_game": "Pievienojas spēlei",
    "loading_fetching_data": "Iegūst servera datus...",
    "loading_finding_game": "Meklē spēli",

    "keybind_clear_tooltip": "Lai noņemtu taustiņu saistījumu, nospiediet taustiņu un pēc tam nospiediet <kbd>Escape</kbd> vai <kbd>Backspace</kbd>.",
    "keybind_reset": "Atiestatīt uz deafult",
    "bindings_+up": "Pārvietot uz augšu",
    "bindings_+down": "Pārvietot uz leju",
    "bindings_+left": "Pārvietot pa kreisi",
    "bindings_+right": "Pārvietot pa labi",
    "bindings_interact": "izmantot",
    "bindings_loot": "loots",
    "bindings_slot 0": "Izvēlēties pirmo",
    "bindings_slot 1": "Izvēlēties otro",
    "bindings_slot 2": "Izvēlēties trešo",
    "bindings_equip_or_cycle_throwables 1": "Izvēlēties/Cikls ar izmetamajiem",
    "bindings_last_item": "Izvēlēties pēdējo ieroci",
    "bindings_other_weapon": "Izvēlēties citu ieroci",
    "bindings_swap_gun_slots": "Nomainīt ieroču slotus",
    "bindings_cycle_items -1": "Izvēlēties iepriekšējo ieroci",
    "bindings_cycle_items 1": "Izvēlēties nākamo ieroci",
    "bindings_+attack": "Izmantot ieroci",
    "bindings_drop": "Nometiet aktīvo ieroci",
    "bindings_reload": "Pārlādēt",
    "bindings_cycle_scopes -1": "Iepriekšējais skats",
    "bindings_cycle_scopes 1": "Nākamais skats",
    "bindings_use_consumable gauze": "Izmantot Marli",
    "bindings_use_consumable medikit": "Izmantot Medikitu",
    "bindings_use_consumable cola": "Izmantot Kolu",
    "bindings_use_consumable tablets": "Izmantot Tabletes",
    "bindings_cancel_action": "Atcelt darbību",
    "bindings_+view_map": "Skatīt karti",
    "bindings_toggle_map": "Ieslēgt pilnekrāna karti",
    "bindings_toggle_minimap": "Ieslēgt minikarti",
    "bindings_toggle_hud": "Pārslēgt HUD",
    "bindings_+emote_wheel": "Emociju Rats",
    "bindings_+map_ping_wheel": "Pārslēgt uz karti ping",
    "bindings_+map_ping": "Kartes ping rats",
    "bindings_toggle_console": "Ieslēgt konsoles",
    "bindings_toggle_slot_lock": "Ieslēgt slota slēdzi",

    "kf_suicide_kill": "<player> izdarīja pašnāvību",
    "kf_suicide_down": "<player> knockoja sevi",

    "kf_bleed_out_kill": "<player> noasiņoja",
    "kf_bleed_out_down": "<player> noasiņoja neletāli",

    "kf_finished_off_kill": "<player> Piebeidza <victim>",
    "kf_finished_off_down": "<player> Maigi piebeidza <victim>",

    "kf_finally_died": "<player> beidzot nomira",
    "kf_finally_ended_themselves": "<player> beidzot paši beidzās",

    "kf_finally_killed": "<player> beidzot tika nogalināts",
    "kf_finally_down": "<player> beidzot tika nokļuva",

    "kf_gas_kill": "<player> nomira no gāzes",
    "kf_gas_down": "<player> nogāzts gar zemi dēļ gāzes",

    "kf_airdrop_kill": "<player> Tika nāvējoši saplacināts ar airdropa palīdzību",
    "kf_airdrop_down": "<player> tika nogāzts gar zemi ar airdropa palīdzību",

    // ------------------------------------------------------------------
    "finally": "beidzot",
    "with": "ar",

    // Kill modal only
    "you": "Tu",
    "yourself": "sevi",
    "km_killed": "nogalināts",
    "km_knocked": "nogāzts gar zemi",

    "km_message": "<you> <finally> <event> <victim> <with> <weapon>",

    // Killfeed.
    "kf_killed": "ogalināja",
    "kf_knocked": "nokļuva",
    "kf_finished_off": "piebeidza",
    "themselves": "sevi",

    "kf_message": "<player> <finally> <event> <victim> <with> <weapon>",
    // ------------------------------------------------------------------

    // Kill Leader stuff
    "kf_kl_promotion": "<player> Tiek paaugstināts par killu līderi!",
    "kf_kl_killed": "<player> nogalināja killu līderi",
    "kf_kl_dead": "Killu līderis ir miris!",
    "kf_kl_suicide": "Killu līderis izdarīja pašnāvību!",

    "tt_restores": "<item> atjauno <amount> <type>",
    "tt_reduces": "<item> samazina <percent>% bojājumus",

    "go_kills": "killi:",
    "go_damage_done": "Izdarītais damage:",
    "go_damage_taken": "Saņemtais damage:",
    "go_time_alive": "Cik ilgi tu biji dzīvs:",

    "create_team": "Izveidot Komandu",
    "create_team_autofill": "Automātiska komandas aizpildīšana",
    "create_team_lock": "Aizslēgt komandu",
    "create_team_waiting": "Gaida...",
    "create_team_play": "Sākt spēli",

    "report_reporting": "reporto",
    "report_id": "reporta ID:",
    "report_instructions": `
      <p><strong>Lūdzu, ievērojiet zemāk esošos norādījumus!</strong> Ja to nedarīsiet, jūsu reports tiks ignorēts.</p>
      <h4>Kā iesniegt reportu</h4>
      <ol>
        <li>Pievienojieties <a href="https://discord.suroi.io">Discord serverim.</a></li>
        <li>Iejiet <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports</a> kanālā.</li>
        <li>Izlasiet reportošanas noteikumus piespraustajā ziņā.</li>
        <li>Iesniedziet savu reportu kā ziņojumu.</li>
      </ol>`,

    "languages": "Valodas",

    // lupata

    "gauze": "Marle",
    "medikit": "Aptieciņa",
    "cola": "Kola",
    "tablets": "Tabletes",

    "basic_vest": "Pamata veste",
    "regular_vest": "Regulāra veste",
    "tactical_vest": "Taktiskā veste",
    "basic_helmet": "Pamata ķivere",
    "regular_helmet": "Regulāra ķivere",
    "tactical_helmet": "Taktiskā ķivere",
    "bag": "Maisiņš",
    "basic_pack": "Pamata mugursoma",
    "regular_pack": "Regulāra mugursoma",
    "tactical_pack": "Taktiskā mugursoma",

    "1x_scope": "1x tēmeklis",
    "2x_scope": "2x tēmeklis",
    "4x_scope": "4x tēmeklis",
    "8x_scope": "8x tēmeklis",
    "15x_scope": "15x tēmeklis",

    "fists": "Dūres",
    "baseball_bat": "Beisbola nūja",
    "hatchet": "Cirvis",
    "kbar": "K-bar",
    "maul": "Āmurs",
    "gas_can": "Gāzes kanna",
    "heap_sword": "HE-AP zobens",
    "steelfang": "Tērauda zobens",
    "ice_pick": "Ledus cirtnis",
    "seax": "Seax",
    "crowbar": "Lauznis",
    "sickle": "Sirpis",

    "mosin": "Mosin-Nagant",
    "radio": "Radio",
    "lewis_gun": "Lewis Gun",
    "hp18": "HP-18",
    "acr": "ACR",
    "saf_200": "SAF-200",
    "deathray": "Nāves stars",
    "usas12": "USAS-12",
    "firework_launcher": "Salūta palaidējs",
    "arena_closer": "Pasauļu iznīcinātājs",
    "revitalizer": "Revitalizēt",
    "s_g17": "G17 (Ar tēmekli)",
    "vss": "VSS",
    "aug": "AUG",
    "pp19": "PP-19",
    "vepr12": "Vepr-12",
    "flues": "Flues",
    "cz75a": "CZ-75A",
    "g19": "G19",
    "mp40": "MP40",
    "m1895": "M1895",
    "ak47": "AK-47",
    "vector": "Vektors",
    "mini14": "Mini-14",
    "model_37": "Model 37",
    "model_89": "Model 89",
    "negev": "Negevs",
    "sr25": "SR-25",
    "tango_51": "Tango 51",
    "barrett": "Barrett M95",
    "stoner_63": "Stoner 63",
    "m1_garand": "M1 Garand",
    "micro_uzi": "Mikro Uzi",
    "m3k": "M3K",
    "arx160": "ARX-160",
    "m16a4": "M16A4",
    "mg36": "MG-36",
    "mcx_spear": "MCX sķēps",

    "frag_grenade": "Frag granāta",
    "smoke_grenade": "Dūmu granāta",
    "confetti_grenade": "Konfekti granāta",

    "dual_template": "Dubultie <gun>",

    "region_dev": "Lokālais serveris",
    "region_na": "Ziemeļamerika",
    "region_eu": "Eiropa",
    "region_sa": "Dienvidamerika",
    "region_as": "Āzija"
};
