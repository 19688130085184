// NOTE: make the crosshair path 16x16 pixels and the view box 20x20 pixels so theres space for the stroke

export const Crosshairs: Array<string | undefined> = [
    undefined, // default
    '<path d="m2.5135417.52916667v1.98437503h-1.98437503v.2645833h1.98437503v1.9843749h.2645833v-1.9843749h1.9843749v-.2645833h-1.9843749v-1.98437503z"/>',

    '<path d="m2.441453.5291666v1.453658h.4092773v-1.453658zm-1.1983765.7002157v1.0924397h.344165v-.7482747h.7482748v-.344165zm1.7135905 0v.344165h.7482747v.7482747h.3441651v-1.0924397zm-2.4406779 1.1983764v.4092774h1.453658v-.4092774zm2.8060303 0v.4092774h1.453658v-.4092774zm-2.0789429.5152141v1.0924397h1.0924398v-.344165h-.7482748v-.7482747zm2.4618652 0v.7482747h-.7482747v.344165h1.0924398v-1.0924397zm-1.2634887.3658691v1.453658h.4092773v-1.453658z"/>',

    '<path d="m 2.6429911,0.52916667 c -0.073289,0 -0.1322916,0.059002 -0.1322916,0.13229166 V 1.0645345 C 1.7411327,1.1286256 1.1257834,1.7439749 1.0616923,2.5135417 H 0.66430054 c -0.0732897,0 -0.13229166,0.059002 -0.13229166,0.1322916 0,0.073289 0.059002,0.1322917 0.13229166,0.1322917 H 1.0611755 c 0.063612,0.7700628 0.6796027,1.38592 1.449524,1.4500406 v 0.4020427 c 0,0.07329 0.059002,0.1322916 0.1322916,0.1322916 0.073289,0 0.1322917,-0.059002 0.1322917,-0.1322916 V 4.2286824 C 3.5457002,4.1650414 4.1621991,3.5485424 4.2258402,2.778125 h 0.4015259 c 0.07329,0 0.1322916,-0.059002 0.1322916,-0.1322917 0,-0.073289 -0.059002,-0.1322916 -0.1322916,-0.1322916 H 4.2253234 C 4.1612028,1.7436204 3.5453456,1.1276296 2.7752828,1.0640177 V 0.66145833 c 0,-0.0732897 -0.059002,-0.13229166 -0.1322917,-0.13229166 z M 2.7752828,1.3286011 c 0.6261544,0.061742 1.1218721,0.5587361 1.183907,1.1849406 H 3.8336161 c -0.07329,0 -0.1322917,0.059002 -0.1322917,0.1322916 0,0.073289 0.059002,0.1322917 0.1322917,0.1322917 H 3.9591898 C 3.8975952,3.4046039 3.4017617,3.9004374 2.7752828,3.962032 V 3.8364583 c 0,-0.07329 -0.059002,-0.1322917 -0.1322917,-0.1322917 -0.073289,0 -0.1322916,0.059002 -0.1322916,0.1322917 V 3.962032 C 1.884495,3.8999971 1.3875006,3.4042794 1.3257589,2.778125 h 0.1322916 c 0.07329,0 0.1322917,-0.059002 0.1322917,-0.1322917 0,-0.073289 -0.059002,-0.1322916 -0.1322917,-0.1322916 H 1.3262756 C 1.3884739,1.8876926 1.8848504,1.3913161 2.5106995,1.3291178 v 0.1260905 c 0,0.07329 0.059002,0.1322917 0.1322916,0.1322917 0.073289,0 0.1322917,-0.059002 0.1322917,-0.1322917 z M 2.6455749,2.4634155 A 0.182548,0.182548 0 0 0 2.4631571,2.6458333 0.182548,0.182548 0 0 0 2.6455749,2.8282511 0.182548,0.182548 0 0 0 2.8285095,2.6458333 0.182548,0.182548 0 0 0 2.6455749,2.4634155 Z" />',

    '<path d="m.94981276.52916708-.42064637.43201478 1.45003981 1.44590634.4278807-.0000001.0000001-.4247802zm3.38067114-.00000056-1.4459065 1.45004098-.0000002.4278807h.4247802l1.4531412-1.45727563zm-2.348177 2.35541208-1.45314118 1.4572756.43201471.420646 1.44590657-1.4500408.0000001-.4278808zm.9022702-.0000002-.0000001.4247803 1.4572745 1.453141.4206494-.4320118-1.4500399-1.4459063z"/>',

    '<path d="M 2.9786295,0.55397134 V 0.92500812 A 1.7565619,1.7565619 0 0 1 4.3651082,2.3114868 H 4.7366617 A 2.1186512,2.1186512 0 0 0 2.9786295,0.55397134 Z M 2.3104533,0.5560384 A 2.1186512,2.1186512 0 0 0 0.55500485,2.3114868 H 0.92449136 A 1.7565619,1.7565619 0 0 1 2.3104533,0.92552489 Z M 2.6447998,1.3358358 A 1.3099111,1.3099111 0 0 0 1.3348022,2.6458333 1.3099111,1.3099111 0 0 0 1.526005,3.3238281 L 1.8334798,3.0163533 a 0.89495105,0.89495105 0 0 1 -0.083716,-0.37052 0.89495105,0.89495105 0 0 1 0.8950358,-0.8950358 0.89495105,0.89495105 0 0 1 0.372587,0.081649 L 3.3238281,1.526005 A 1.3099111,1.3099111 0 0 0 2.6447998,1.3358358 Z M 3.7641113,1.9662882 3.45767,2.2727295 A 0.89495105,0.89495105 0 0 1 3.5398356,2.6458333 0.89495105,0.89495105 0 0 1 2.6447998,3.5408691 0.89495105,0.89495105 0 0 1 2.273763,3.4566365 L 1.9662882,3.7641113 A 1.3099111,1.3099111 0 0 0 2.6447998,3.9558308 1.3099111,1.3099111 0 0 0 3.9547973,2.6458333 1.3099111,1.3099111 0 0 0 3.7641113,1.9662882 Z M 2.6447998,2.4029541 A 0.24300306,0.24300306 0 0 0 2.4019205,2.6458333 0.24300306,0.24300306 0 0 0 2.6447998,2.8887125 0.24300306,0.24300306 0 0 0 2.887679,2.6458333 0.24300306,0.24300306 0 0 0 2.6447998,2.4029541 Z M 0.55500485,2.979663 A 2.1186512,2.1186512 0 0 0 2.3104533,4.7356282 V 4.3661417 A 1.7565619,1.7565619 0 0 1 0.92397457,2.979663 Z m 3.81062005,0 A 1.7565619,1.7565619 0 0 1 2.9786295,4.3666585 V 4.7376952 A 2.1186512,2.1186512 0 0 0 4.7366617,2.979663 Z" />',

    '<path d="M 2.6458332,0.52916667 A 2.1166668,2.1166668 0 0 0 0.52916662,2.6458334 2.1166668,2.1166668 0 0 0 2.6458332,4.7624999 2.1166668,2.1166668 0 0 0 4.7624999,2.6458334 2.1166668,2.1166668 0 0 0 2.6458332,0.52916667 Z m 0,0.38602294 A 1.7304204,1.7304204 0 0 1 3.7243203,1.2929443 L 2.6458332,2.3714315 1.5694131,1.2955281 A 1.7304204,1.7304204 0 0 1 2.6458332,0.91518961 Z M 3.9987222,1.5673463 A 1.7304204,1.7304204 0 0 1 4.3764769,2.6458334 1.7304204,1.7304204 0 0 1 3.9987222,3.7243205 L 2.920235,2.6458334 Z M 1.2950113,1.5699301 2.3709146,2.6458334 1.2950113,3.7217367 A 1.7304204,1.7304204 0 0 1 0.91518952,2.6458334 1.7304204,1.7304204 0 0 1 1.2950113,1.5699301 Z M 2.6453164,2.9202352 3.7243203,3.9987224 A 1.7304204,1.7304204 0 0 1 2.6458332,4.3764771 1.7304204,1.7304204 0 0 1 1.5694131,3.9961386 Z" />',

    '<path id="path1" d="M 2.6458333,0.52916665 A 2.1166674,2.1166674 0 0 0 0.52916665,2.6458333 2.1166674,2.1166674 0 0 0 2.6458333,4.7624999 2.1166674,2.1166674 0 0 0 4.7624999,2.6458333 2.1166674,2.1166674 0 0 0 2.6458333,0.52916665 Z m 0,0.37310384 A 1.7437392,1.7437392 0 0 1 4.3893961,2.6458333 1.7437392,1.7437392 0 0 1 2.6458333,4.3893961 1.7437392,1.7437392 0 0 1 0.90227049,2.6458333 1.7437392,1.7437392 0 0 1 2.6458333,0.90227049 Z M 2.5460978,2.3859008 v 0.160197 h -0.160197 v 0.199471 h 0.160197 v 0.1601969 h 0.199471 V 2.7455688 H 2.9057657 V 2.5460978 H 2.7455688 v -0.160197 z" />',

    '<path d="M 2.3796997,0.52916665 V 1.7600992 H 2.9119669 V 0.52916665 Z M 2.9993001,0.83405759 V 1.2236979 A 1.4707091,1.4707091 0 0 1 4.0731363,2.2923665 H 4.4617431 A 1.8502945,1.8502945 0 0 0 2.9993001,0.83405759 Z M 2.2923665,0.83457435 A 1.8502945,1.8502945 0 0 0 0.83405759,2.2923665 H 1.2226644 A 1.4707091,1.4707091 0 0 1 2.2923665,1.2247314 Z M 0.52916665,2.3796997 V 2.9119669 H 1.7600992 V 2.3796997 Z m 3.00240065,0 V 2.9119669 H 4.7624999 V 2.3796997 Z M 0.83405759,2.9993001 A 1.8502945,1.8502945 0 0 0 2.2944335,4.457609 V 4.0674519 A 1.4707091,1.4707091 0 0 1 1.2226644,2.9993001 Z m 3.23494461,0 A 1.4707091,1.4707091 0 0 1 3.0013671,4.0664184 v 0.390157 A 1.8502945,1.8502945 0 0 0 4.457609,2.9993001 Z M 2.3796997,3.5315673 V 4.7624999 H 2.9119669 V 3.5315673 Z" />',

    '<path d="M 2.6458333,0.20153806 0.52916665,1.4236857 V 3.8679809 L 2.6458333,5.0901285 4.7624999,3.8679809 V 1.4236857 Z m 0,0.47077229 L 4.1692545,1.55236 2.661853,2.4225911 1.137915,1.5430582 Z M 0.93689369,1.8872233 2.4499796,2.7610717 V 4.5061848 L 0.93689369,3.6323363 Z M 4.3547729,1.90531 v 1.7270263 l -1.506368,0.8702311 v -1.727543 z" />',

    '<path d="M 2.4179402,0.31677652 1.6190226,1.7006713 1.0454142,1.3694254 0.74259031,1.8939412 1.3156819,2.2251871 0.52916665,3.5878946 1.1275797,3.2426961 2.4179402,1.0076904 Z m 0.4557861,0 V 1.0076904 L 4.1640868,3.2426961 4.7624999,3.5878946 3.9759846,2.2251871 4.5490762,1.8939412 4.2462523,1.3694254 3.672644,1.7006713 Z M 2.6458333,2.4024373 c -0.1344125,2.74e-5 -0.2433686,0.1089835 -0.243396,0.243396 2.74e-5,0.1344125 0.1089835,0.2433686 0.243396,0.243396 0.1344125,-2.74e-5 0.2433686,-0.1089835 0.243396,-0.243396 C 2.8892019,2.5114208 2.7802458,2.4024647 2.6458333,2.4024373 Z M 1.3192993,3.6581746 0.75705971,3.9832193 H 2.3430094 V 4.665865 H 2.9486572 V 3.9832193 H 4.5346068 L 3.9723673,3.6581746 Z" />',

    '<path d="M 2.4318929,0.52916665 V 0.83095701 A 1.8305234,1.8305234 0 0 0 0.83095701,2.4318929 H 0.52916665 V 2.8597737 H 0.83095701 A 1.8305234,1.8305234 0 0 0 2.4318929,4.4607095 V 4.7624999 H 2.8597737 V 4.4607095 A 1.8305234,1.8305234 0 0 0 4.4607095,2.8597737 H 4.7624999 V 2.4318929 H 4.4607095 A 1.8305234,1.8305234 0 0 0 2.8597737,0.83095701 V 0.52916665 Z M 2.8597737,1.2407511 A 1.4216013,1.4216013 0 0 1 4.0509154,2.4318929 H 2.9739786 a 0.39187496,0.39187496 0 0 1 0.063562,0.2139404 0.39187496,0.39187496 0 0 1 -0.063562,0.2139404 H 4.0509154 A 1.4216013,1.4216013 0 0 1 2.8597737,4.0509154 V 2.9739786 A 0.39187496,0.39187496 0 0 1 2.6458333,3.0375406 0.39187496,0.39187496 0 0 1 2.4318929,2.9739786 v 1.0764201 a 1.4216013,1.4216013 0 0 1 -1.190625,-1.190625 h 1.07642 A 0.39187496,0.39187496 0 0 1 2.2541259,2.6458333 0.39187496,0.39187496 0 0 1 2.3176879,2.4318929 h -1.07642 a 1.4216013,1.4216013 0 0 1 1.190625,-1.190625 v 1.07642 a 0.39187496,0.39187496 0 0 1 0.2139404,-0.063562 0.39187496,0.39187496 0 0 1 0.2139404,0.063562 z M 2.6458333,2.3430094 A 0.30300418,0.30300418 0 0 0 2.3430094,2.6458333 0.30300418,0.30300418 0 0 0 2.6458333,2.9486572 0.30300418,0.30300418 0 0 0 2.9486572,2.6458333 0.30300418,0.30300418 0 0 0 2.6458333,2.3430094 Z" />',

    // clickbait arrow
    '<path d="M 1.3519537,2.2068079 C 1.357793,2.1741734 1.637807,2.2525412 1.9879066,2.1096426 1.6258102,1.556659 0.50167915,1.0305177 0.50167915,1.0305177 L 1.0118737,0.47138692 C 1.3086718,0.58971178 1.5818046,0.75145785 1.8384366,0.93722354 2.2934379,1.2785435 2.4561453,1.5675021 2.4905588,1.5940244 2.5158608,1.6135334 2.6088899,1.1368476 2.6088899,1.1368476 l 0.097282,0.00117 c 0,0 0.2137755,1.0403756 -0.029994,1.5078154 C 2.2339244,2.5140167 1.7847855,2.4073078 1.3338091,2.3082146 Z" />'
];

export function getCrosshair(
    index: number,
    color: string,
    size: number,
    strokeColor: string,
    strokeSize: number
): string {
    const crosshair = Crosshairs[index];
    if (!crosshair) return "crosshair";
    return `data:image/svg+xml,${encodeURIComponent(`<svg fill="${color}" height="${size}" width="${size}" stroke="${strokeColor}" stroke-width="${strokeSize}" viewBox="0 0 5.2916665 5.2916666" xmlns="http://www.w3.org/2000/svg">${crosshair}</svg>`)}`;
}
