/*
    <div class="loading-buttons-div">
      <button class="loading-btn-okay btn btn-lg btn-darken btn-secondary" id="loading-button-okay">Okay</button>
      <button class="loading-btn-cancel btn btn-lg btn-darken btn-secondary" id="loading-button-cancel">Cancel</button>
      <button class="loading-btn-refresh btn btn-lg btn-darken btn-secondary" id="loading-button-refresh">Refresh</button>
    </div>
*/

import $ from "jquery";
import { getTranslatedString } from "../../translations";

export class UILoader {
    private static dialog: JQuery<HTMLDivElement>;
    private static textSpan: JQuery<HTMLSpanElement>;
    private static refresh: JQuery<HTMLButtonElement>;
    private static cancel: JQuery<HTMLButtonElement>;
    private static okay: JQuery<HTMLButtonElement>;

    private static stack: { 
        key: string, 
        value: string, 
        refreshFunction?: () => Promise<void>, 
        cancelFunction?: () => Promise<void>,
        okayFunction?: () => Promise<void> 
    }[] = [];

    static init() {
        if (!this.dialog) {
            this.dialog = $("#loading-dialog") as JQuery<HTMLDivElement>;
            this.textSpan = $("#loading-dialog-text") as JQuery<HTMLSpanElement>;
            this.refresh = $("#loading-button-refresh") as JQuery<HTMLButtonElement>;
            this.cancel = $("#loading-button-cancel") as JQuery<HTMLButtonElement>;
            this.okay = $("#loading-button-okay") as JQuery<HTMLButtonElement>;
        }
    }

    static addTranslated(key: string) {
        this.init();

        this.add(key, getTranslatedString(key));
    }

    static add(key: string, value: string, refreshFunc?: () => Promise<void>, canCancel?: boolean, canOkay?: boolean) {
        this.init();

        let refresh = async () => {
            console.log("refresh");
            await refreshFunc?.();
        };
        
        let cancel = async () => {
            console.log("cancel");
            this.init();
            this.remove(key); // Call the remove method with the key
            this.update();     // Call the update method after removal
        };
        let okay = async () => {
            console.log("ok");
            this.init();
            this.remove(key); // Call the remove method with the key
            this.update();     // Call the update method after removal
        };

        let existing = this.stack.find(item => item.key === key);
        if (existing) {
            existing.value = value;
            existing.refreshFunction = refreshFunc ? refresh : undefined;
            existing.cancelFunction = canCancel ? cancel : undefined;
            existing.okayFunction = canOkay ? okay : undefined;
        }
        else{
            // Add the object to the stack, with optional refreshFunction and cancelFunction
            this.stack.push({ 
                key, 
                value, 
                refreshFunction: refreshFunc ? refresh : undefined, 
                cancelFunction: canCancel ? cancel : undefined, 
                okayFunction: canOkay ? okay : undefined 
            });
        }

        // Update the stack
        this.update();
    }


    static remove(textKey: string) {
        this.init();

        this.stack = this.stack.filter(item => item.key !== textKey);
        this.update();
    }

    static update() {
        this.init();

        if (this.stack.length > 0) {
            this.dialog.show();
            this.textSpan.text(this.stack[0].value);
            console.log(`[loading] ${this.stack[0].key}: ${this.stack[0].value}`);

            if (this.stack[0].refreshFunction) {
                this.refresh.show();
                this.refresh.off('click').on('click', async () => {
                    await this.stack[0].refreshFunction?.();
                });
            } else {
                UILoader.refresh.hide();
            }

            if (this.stack[0].cancelFunction) {
                this.cancel.show();
                this.cancel.off('click').on('click', async () => {
                    await this.stack[0].cancelFunction?.();});
            } else {
                this.cancel.hide();
            }

            if (this.stack[0].okayFunction) {
                this.okay.show();
                this.okay.off('click').on('click', async () => {await this.stack[0].okayFunction?.();});
            } else {
                this.okay.hide();
            }
        }
        else {
            this.dialog.hide();
        }
    }
}