import type { TranslationMap } from "../translations";

export const TAMIL_TRANSLATIONS: TranslationMap = {
    "name": "தமிழ்",
    "flag": "🇮🇳",

    "msg_rotate": "நல்ல அனுபவத்திற்கு கருவியை கிடைமட்டமாக திருப்பவும்.",
    "msg_loading": "தொடர்பு கொல்கிறது",
    "msg_err_joining": "விளையாட்டில் சேர முடியவில்லை.",
    "msg_err_finding": "விளையாட்டை கண்டுபிடிக்க இயலவில்லை.",
    "msg_try_again": "மருபடியும் முயர்சிக்கவும்.",
    "msg_warning": "நீங்கள் எச்சரிக்கப்பட்டிருக்கிறீர்!",
    "msg_warning_msg": "நிர்வாகிகள் உன்னை எச்சரித்துள்ளனர். காரணம்: <reason>",
    "msg_temp_ban": "நீ தற்காலிகமாய் தடை செய்யப்பட்டுள்ளீர்!",
    "msg_temp_ban_msg": "நீ தடை செய்யப்பட்டுள்ளாய்! காரணம்: <reason>",
    "msg_perma_ban": "நீ வாழ்நால் முழுவதும் தடை செய்யப்பட்டுள்ளாய்!",
    "msg_perma_ban_msg": "நீ தடை செய்யப்பட்டுள்ளாய். காரணம்: <reason>",
    "msg_no_reason": "காரணம் கொடுக்கவில்லை.",
    "msg_spectating": "பார்வையிடுகிறீர்",
    "msg_enter_team_code": "குழுமத்தின் எண்னை செலுத்துக:",
    "msg_lost_team_connection": "உன்னுடைய குழுமத்தின் தொடர்பு துன்டிக்கப்பட்டது.",
    "msg_error_joining_team": "குழுமத்தில் சேரமுடியவில்லை<br>அந்த குழுமம் இருக்காமல் இருக்கலாம், அல்லது முழுமையாக இருக்கலாம்.", // <br> here is an HTML break-line element. DO NOT TOUCH OR MOVE.

    "play_solo": "ஒற்றைவீரராக விளையாடு",
    "play_duo": "இரட்டைவீரராக விளையாடு",
    "play_squad": "நான்குவீரராக விளையாடு",
    "join_team": "குழுமத்தில் சேர்",
    "msg_locked_tooltip": "இவ்விளையாட்டு ஒவ்வொரு 24 மனிநேரமும் ஒற்றைவீரர் மற்றும் இரட்டைவீரர் நடுவில் மாறும். எனென்றால் நிறைய வீரர்கள் அல்ல.",
    "rules_and_tutorial": "விதிமுறைகள் & பயிற்சி",
    "loadout": "அணிகலன்கள்",
    "settings": "அமைப்புகள்",
    "fullscreen": "முழுதிரை",
    "resume": "தொடரு",
    "quit": "வெளியேறு",
    "none": "அல்ல",
    "copy": "நகலெடு",
    "copied": "நகலெடுக்கப்பட்டது",

    "health": "உடல்நிலை",
    "adrenaline": "சக்தி",

    "settings_volume": "சத்த அளவு",
    "settings_keybinds": "விசைபினைப்புகள்",
    "settings_graphics": "கிராஃபிக்ஸ்",
    "settings_interface": "இன்டர்ஃபேஸ்",
    "settings_save_load": "பதிவிறக்கு/பதிவேற்று",
    "settings_mobile": "அலைபேசி",
    "settings_require_reload": "* இந்த அமைப்புகளை மாற்றினால் ரீலோட் செய்யவேண்டும்",
    "settings_performance_warning": "* இந்த அமைப்பு சில கருவிகளிள் பிரச்சனைகளை ஏர்படுத்தலாம். விளையாட முடியவில்லை என்றால் அனைக்கவும்.",

    "settings_master_volume": "சத்த அளவு",
    "settings_sfx_volume": "SFX சத்த அளவு",
    "settings_music_volume": "பாட்டு சத்த அளவு",
    "settings_old_menu_music": "பழைய மெனு பாட்டு",

    "settings_render_mode": "ரென்டர் மோட்",
    "settings_render_resolution": "ரென்டர் ரெஸல்யூஷன்",
    "settings_render_resolution_auto": "தானியங்கி",
    "settings_hires_textures": "அதிக ரெஸல்யூஷன் படங்கள்",
    "settings_cooler_graphics": "மேம்பட்ட கிராஃபிக்ஸ்",
    "settings_antialias": "விளையாட்டு அனுபவத்தை மேம்படுத்து",
    "settings_movement_smoothing": "மேம்படுத்திய இயக்கம்",
    "settings_responsive_rotation": "மேம்படுத்திய திருப்பம்",
    "settings_camera_shake": "கேமரா ஷேக்",

    "settings_interface_scale": "இன்டர்ஃபேஸ் அளவுகோல்",
    "settings_minimap_opacity": "சிறிய வரைபடத்தின் மறைப்புதன்மை",
    "settings_fs_map_opacity": "முழுதிறை வரைபட மறைப்புதன்மை",
    "settings_hide_minimap": "சிறிய வரைபடத்தை மறைக்கவும்",
    "settings_blur_splash": "மங்கலான ஸ்ப்லாஷ் ஸ்க்ரீன்",
    "settings_hide_rules": "விதிமுறை பட்டனை மறைக்கவும்",
    "settings_warn_before_leaving": "வெளியேருவதன்முன் எச்சரிக்கவும்",
    "settings_show_fps": "FPSஐ காட்டு",
    "settings_show_ping": "pingஐ காட்டு",
    "settings_show_coordinates": "இடத்தை காட்டு",
    "settings_anon_names": "மற்ற வீரரின் பெயரை மாற்று",
    "settings_hide_emotes": "உணர்ச்சிகளை மறைக்கவும்",
    "settings_text_killfeed": "எழுத்து சார்ந்த கொலை குறியீடு",
    "settings_colored_weapon_slots": "வண்ணமான ஆயுதகாட்டி",
    "settings_scope_looping": "உருப்பெருக்கான தேர்ந்தெடுத்தலை Loop செய்யவும் ",
    "settings_draw_hud": "HUDஐ வரையவும்",
    "settings_autopickup": "தானாக பொருட்களை எடுக்க",
    "settings_autopickup_dual_guns": "இரட்டை துப்பாக்கிகளை தானாக எடுக்க",

    "settings_load_settings": "அமைப்புகளை பதிவேற்றவும்",
    "settings_copy_settings": "அமைப்புகளை நகலெடுக்கவும்",
    "settings_reset_settings": "அமைப்புகளை மீட்டமைக்கவும்",

    "settings_reload": "ரீலோட்",
    "settings_mobile_controls": "அலைபேசி கட்டுப்பாடுகள்",
    "settings_joystick_size": "ஜாய்ஸ்டிக் அளவு",
    "settings_joystick_opacity": "ஜாய்ஸ்டிக் வெளிப்படைதன்மை",

    "loadout_skins": "ஆடைகள்",
    "loadout_emotes": "உணர்ச்சிகள்",
    "loadout_crosshairs": "சுட்டிகள்",
    "loadout_badges": "பதக்கங்கள்",
    "loadout_special": "சிறப்பானது",
    "loadout_crosshairs_default": "கருவியுடன் வந்தது",
    "loadout_crosshairs_size": "அளவு:",
    "loadout_crosshairs_color": "வண்ணம்:",
    "loadout_crosshairs_stroke_size": "எல்லை-கோடு அளவு:",
    "loadout_crosshairs_stroke_color": "எல்லை-கோடு வண்ணம்:",

    "emotes_category_People": "மனிதர்கள்",
    "emotes_category_Text": "எழுத்துகள்",
    "emotes_category_Memes": "மீம்ஸுகள் (மொக்க)",
    "emotes_category_Icons": "படங்கள்",
    "emotes_category_Misc": "மேலும்",

    "featured_youtubr": "சிறந்த Youtubr",
    "featured_streamr": "சிறந்த Streamr",

    "btn_report": "புகாரளி",
    "btn_spectate_kill_leader": "கொலை முன்னனியை பார்வையிடு",
    "btn_spectate": "பார்வையிடு",
    "btn_play_again": "மருபடியும் விளையாடு",
    "btn_menu": "மெனு பக்கம்",

    "msg_waiting_for_leader": "முன்னனிக்காக காத்திறு",
    "msg_you_died": "நீ செத்தாய்.",
    "msg_player_died": "<player> செத்தார்.",
    "msg_win": "வெற்றி வெற்றி சிக்கன் விருந்து!",
    "msg_your_rank": "தரவரிசை",

    "msg_kills": "கொலைகள்: <kills>",

    "gas_waiting": "கொடிய வாயு <time>இல் முன்னேரும்",
    "gas_advancing": "கொடிய வாயு முன்னேருகிறது! பாதுகாப்பான பகுதிகளுக்கு செல்!",
    "gas_inactive": "வீரர்களுக்காக காத்திறுக்கிறது...",

    "action_open_door": "கதவை திர",
    "action_close_door": "கதவை மூடு",
    "action_revive": "<player>ஐ காப்பாற்று",
    "action_cancel": "நிறுத்து",
    "action_reloading": "ரீலோட் ...",
    "action_reviving": "காப்பிற்றுகிறாய்...",
    "action_being_revived": "காப்பாற்றப்படுகிறாய்...",
    "action_gauze_use": "<item> கட்டப்படுகிறது",
    "action_medikit_use": "<item> பயன்படுத்தபடுகிறது",
    "action_cola_use": "<item> குடிக்கப்படுகிறது",
    "action_tablets_use": "<item> முழுங்கபடுகிறது",

    "loading_spritesheets": "Spritesheet பதிவிறக்கப்படுகிறது: <progress>",
    "loading_connecting": "தொடர்பு கொள்கிறது",
    "loading_joining_game": "விளையாட்டிள் சேர்கிறது",
    "loading_fetching_data": "சர்வர் விவரங்கள் பதிவிறக்கப்படுகின்றன...",
    "loading_finding_game": "விளையாட்டை தேடுகிறது",

    "keybind_clear_tooltip": "ஒரு விசைபினைப்பை அகற்ற, அந்த விசைபினைப்பு பட்டனை அழுத்திய பிறகு இவ்விரண்டில் ஒன்றை அழுத்தவும்:<kbd>Escape</kbd> அல்லது <kbd>Backspace</kbd>.",
    "keybind_reset": "நிலைத்த பினைப்புகளுக்கு மீட்டமைக்க",
    "bindings_+up": "மேலே நகர",
    "bindings_+down": "கீழே நகர",
    "bindings_+left": "இடதுபுறம் நகர",
    "bindings_+right": "வலதுபுறம் நகரt",
    "bindings_interact": "இயக்க",
    "bindings_loot": "எடுக்க",
    "bindings_slot 0": "முதலாவதை பயன்படுத்த",
    "bindings_slot 1": "இரண்டாவதை பயன்படுத்த",
    "bindings_slot 2": "கையாயுதத்தை பயன்படுத்த",
    "bindings_equip_or_cycle_throwables 1": "குண்டுகளை எடுக்க/மாற்ற",
    "bindings_last_item": "அன்மை ஆயுதத்தை பயன்படுத்த",
    "bindings_other_weapon": "மற்ற துப்பாக்கியை பயன்படுத்த",
    "bindings_swap_gun_slots": "துப்பாக்கி இடமாற்ற",
    "bindings_cycle_items -1": "முந்திய ஆயுதத்தை பயன்படுத்த",
    "bindings_cycle_items 1": "அடுத்த ஆயுதத்தை பயன்படுத்த",
    "bindings_+attack": "ஆயுதத்தை இயக்க",
    "bindings_drop": "வைத்திறுக்கும் ஆயுதத்தை போட",
    "bindings_reload": "ரீலோட் செய்ய",
    "bindings_cycle_scopes -1": "முந்திய உருப்பெருக்கான்",
    "bindings_cycle_scopes 1": "அடுத்த உருப்பெருக்கான்",
    "bindings_use_consumable gauze": "கட்டுகளை கட்ட",
    "bindings_use_consumable medikit": "மருந்துபெட்டியை பயன்படுத்த",
    "bindings_use_consumable cola": "கோலாவை குடிக்க",
    "bindings_use_consumable tablets": "மாத்திறைகளை முழுங்க",
    "bindings_cancel_action": "செயலை நிறுத்த",
    "bindings_+view_map": "களத்தை பாற்க்க",
    "bindings_toggle_map": "முழுதிரை வரைபடத்தை காண்பிக்க/ மறைக்க",
    "bindings_toggle_minimap": "சிறிய வரைபடம் காண்பிக்க/ மறைக்க",
    "bindings_toggle_hud": "HUD காண்பிக்க/ மறைக்க",
    "bindings_+emote_wheel": "உணர்ச்சி சக்கரம்",
    "bindings_+map_ping_wheel": "கள குறியீடுக்கு மாற",
    "bindings_+map_ping": "கள குறியீடு சக்கரம்",
    "bindings_toggle_console": "Console காண்பிக்கவும்",
    "bindings_toggle_slot_lock": "ஸ்லாட் பூட்டு",

    "kf_suicide_kill": "<player> தற்கொலை செய்துகொண்டார்",
    "kf_suicide_down": "<player> தன்னைதானே செயலிழக்க செய்தார்",

    "kf_bleed_out_kill": "<player> இரத்தம் இழந்து செத்தார்",
    "kf_bleed_out_down": "<player> இரத்தம் இழந்தார்",

    "kf_finished_off_kill": "<player> <victim>-ஐ முடித்தார்",
    "kf_finished_off_down": "<player> கருனையுடன் <victim>-ஐ முடித்தார்",

    "kf_finally_died": "<player> இருதியில் செத்தார்",
    "kf_finally_ended_themselves": "<player> இருதியில் தற்கொலை செய்தார்",

    "kf_finally_killed": "<player> இருதியில் கொல்லப்பட்டார்",
    "kf_finally_down": "<player> ஒருவழியாக செயலிழந்தார்",

    "kf_gas_kill": "<player> கொடிய வாயுவினால் செத்தார்",
    "kf_gas_down": "<player> கொடிய வாயுவினால் செயலிழந்தார்",

    "kf_airdrop_kill": "<player> கருனையில்லாமல் காற்றுபெட்டியால் செத்தார்",
    "kf_airdrop_down": "<player> காற்றுபெட்டியால் செயலிழந்தார்",

    "finally": "இறுதியாக",
    "with": "வைத்து",

    // Kill modal only
    "you": "நீ",
    "yourself": "உன்னையே",
    "km_killed": "கொன்றாய்",
    "km_knocked": "செயலிழக்க செய்தாய்",

    "km_message": "<you> <finally> <victim>-ஐ <weapon>-ஐ <with> <event>",

    // Killfeed.
    "kf_killed": "கொன்றார்",
    "kf_knocked": "செயலிழக்க செய்தார்",
    "kf_finished_off": "முடித்தார்",
    "themselves": "தன்னைதானே",

    "kf_message": "<player> <finally> <victim>-ஐ <weapon>-ஐ <with> <event>",
    // ------------------------------------------------------------------

    // Kill Leader stuff
    "kf_kl_promotion": "<player> கொலை முன்னனியானார்!",
    "kf_kl_killed": "<player> கொலை முன்னனியாளரை கொலை செய்தார்",
    "kf_kl_dead": "கொலை முன்னனியாளர் இறந்தார்!",
    "kf_kl_suicide": "கொலை முன்னனியாளர் தற்கொலை செய்தார்!",

    "tt_restores": "<item> <amount>ஐ திருப்பி தரும் <type>",
    "tt_reduces": "<item> <percent>% சேதத்தை குறைக்கும்",

    "go_kills": "கொலைகள்:",
    "go_damage_done": "செய்த சேதம்:",
    "go_damage_taken": "எடுத்த சேதம்:",
    "go_time_alive": "உயிருடன் இருந்த நேரம்:",

    "create_team": "குழுமத்தை உருவாக்கு",
    "create_team_autofill": "தானாக செலுத்து",
    "create_team_lock": "குழுமத்தை பூட்டு",
    "create_team_play": "விளையாட்டை ஆரம்பி",

    "report_reporting": "புகார் எண்:",
    "report_id": "புகார் என்:",
    "report_instructions": `
      <p><strong>இங்குள்ள குறியீடுகளை நங்கு கவனிக்கவும். </strong>அல்லது, உன்னுடயை புகார் நிறாகறிக்கபடும்.</p>
      <h4>புகாரளிப்பது எப்படி</h4>
      <ol>
        <li><a href="https://discord.suroi.io">Discord.</a> சர்வரில் சேரவும்</li>
        <li><a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports</a> சானலிற்கு செல்லவும்</li>
        <li>அங்குள்ள முதன்மையான பதிப்பிளுள்ள புகார் விதிமுறைகளை நன்கு படிக்கவும்.</li>
        <li>உன் புகாரை அங்கு பதிப்பாக செலுத்தவும்.</li>
      </ol>`,

    "languages": "மொழிகள்",
    // loot

    "gauze": "கட்டுகள்",
    "medikit": "மருந்துபெட்டி",
    "cola": "கோலா",
    "tablets": "மாத்திரைகள்",

    "basic_vest": "அடிப்படை உடுப்பு",
    "regular_vest": "வழக்கமான உடுப்பு",
    "tactical_vest": "தந்திரோபாய உடுப்பு",
    "basic_helmet": "அடிப்படையான தலைகவசம்",
    "regular_helmet": "வழக்கமான தலைகவசம்",
    "tactical_helmet": "தந்திரோபாய தலைகவசம்",
    "bag": "பை", // This shouldn't show up in game
    "basic_pack": "அடிப்படையான முதுகுப்பை",
    "regular_pack": "வழக்கமான முதுகுப்பை",
    "tactical_pack": "தந்திரோபாய முதுகுப்பை",

    "1x_scope": "1வது நிலை உருப்பெருக்கான்", // This shouldn't show up in game
    "2x_scope": "2வது நிலை உருப்பெருக்கான்",
    "4x_scope": "4வது நிலை உருப்பெருக்கான்",
    "8x_scope": "8வது நிலை உருப்பெருக்கான்",
    "15x_scope": "15வது நிலை உருப்பெருக்கான்",

    "fists": "கைகள்",
    "baseball_bat": "மட்டை",
    "hatchet": "கோடாரி",
    "kbar": "கே-பார்",
    "maul": "மால்",
    "gas_can": "வாயு கொள்கலன்",
    "heap_sword": "HE-AP வாள்",
    "steelfang": "ஸ்டீல்ஃபேன்கு",
    "ice_pick": "பனி கோடாரி",
    "seax": "ஸியக்ஸ்",
    "crowbar": "கடப்பாரை",
    "sickle": "அரிவாள்",

    "radio": "வானொலி",
    "deathray": "கொடிய கதிர்",

    "frag_grenade": "கையெறி குண்டு",
    "smoke_grenade": "புகைகுண்டு",
    "confetti_grenade": "கன்ஃபெட்டி குண்டு",

    "mosin": "Mosin-Nagant",
    "lewis_gun": "Lewis Gun",
    "hp18": "HP-18",
    "acr": "ACR",
    "saf_200": "SAF-200",
    "usas12": "USAS-12",
    "firework_launcher": "Firework Launcher",
    "arena_closer": "Destroyer Of Worlds",
    "revitalizer": "Revitalizer",
    "s_g17": "G17 (Scoped)",
    "vss": "VSS",
    "aug": "AUG",
    "pp19": "PP-19",
    "vepr12": "Vepr-12",
    "flues": "Flues",
    "cz75a": "CZ-75A",
    "g19": "G19",
    "mp40": "MP40",
    "m1895": "M1895",
    "ak47": "AK-47",
    "vector": "Vector",
    "mini14": "Mini-14",
    "model_37": "Model 37",
    "model_89": "Model 89",
    "negev": "Negev",
    "sr25": "SR-25",
    "tango_51": "Tango 51",
    "barrett": "Barrett M95",
    "stoner_63": "Stoner 63",
    "m1_garand": "M1 Garand",
    "micro_uzi": "Micro Uzi",
    "m3k": "M3K",
    "arx160": "ARX-160",
    "m16a4": "M16A4",
    "mg36": "MG-36",
    "mcx_spear": "MCX Spear",

    // For dual guns
    "dual_template": "இரட்டை <gun>",

    "region_dev": "உங்கள் வீட்டு சர்வர்",
    "region_na": "வட அமேரிக்கா",
    "region_eu": "ஐரோப்பா",
    "region_sa": "தென் அமேரிக்கா",
    "region_as": "ஆசியா"
};
