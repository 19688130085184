import type { TranslationMap } from "../translations";

export const ROMANIAN_TRANSLATIONS: TranslationMap = {
    "name": "Română",
    "flag": "🇷🇴",

    "msg_rotate": "Pentru o experiență mai bună, vă rog  rotate your device to landscape.",
    "msg_loading": "Conectare",
    "msg_err_joining": "Error joining game.",
    "msg_err_finding": "Error finding game.",
    "msg_try_again": "Please try again.",
    "msg_warning": "You have been warned!",
    "msg_warning_msg": "You have received a warning by the moderators for reason: <reason>",
    "msg_temp_ban": "You have been temporarily banned!",
    "msg_temp_ban_msg": "You have been banned for reason: <reason>",
    "msg_perma_ban": "You have been permanently banned!",
    "msg_perma_ban_msg": "You have been banned for reason: <reason>",
    "msg_no_reason": "No reason provided.",

    "play_solo": "Joacăte Singur",
    "play_duo": "Joacăte Duo",
    "play_squad": "Joacăte Squad",
    "join_team": "Intră În Echipă",
    "msg_locked_tooltip": "Joaca se schimbă între solos și duos în fiecare 24 de ore, fiindcă nu sunt destuli jucători pentru ambele.",
    "rules_and_tutorial": "Reguli & Tutorial",
    "news": "Noutăți",
    "loadout": "Încărcare",
    "settings": "Setări",
    "fullscreen": "Ecran Complet",
    "resume": "Reluați",
    "quit": "Ieșire",
    "none": "Nimic",
    "copy": "Copiază",
    "copied": "Copiat",

    "health": "viață",
    "adrenaline": "adrenalină",

    "settings_volume": "Volum",
    "settings_keybinds": "Taste",
    "settings_graphics": "Grafică",
    "settings_interface": "Interfață",
    "settings_save_load": "Salvează/Încarcă",
    "settings_mobile": "Mobil",
    "settings_require_reload": "* Schimbarea acestei setări cere reîncărcarea paginii",
    "settings_performance_warning": "* Această setare poate cauza probleme la unele dispozitive. Dezactivați dacă nu puteți intra în joacă.",

    "settings_master_volume": "Volum principal",
    "settings_sfx_volume": "Volum SFX",
    "settings_music_volume": "Volum la muzică",
    "settings_old_menu_music": "Muzică veche de meniu",

    "settings_render_mode": "Mod de randare",
    "settings_render_resolution": "Rezoluția de randare",
    "settings_render_resolution_auto": "Automatic",
    "settings_hires_textures": "Texture cu rezoluții înalte",
    "settings_cooler_graphics": "Grafici mai interesante",
    "settings_antialias": "Anti-aliasing",
    "settings_movement_smoothing": "Netezirea mișcării",
    "settings_responsive_rotation": "Rotație receptivă",
    "settings_camera_shake": "Tremurări de cameră",

    "settings_interface_scale": "Mărimea interfeței",
    "settings_minimap_opacity": "Opacitatea la minimapei",
    "settings_fs_map_opacity": "Opacitatea Mapei Complete",
    "settings_hide_minimap": "Ascunde minimap-ul",
    "settings_blur_splash": "Blurează ecranul de introducere",
    "settings_hide_rules": "Ascunde butonul cu reguli",
    "settings_warn_before_leaving": "Avertizează înainte să pleci",
    "settings_show_fps": "Arată FPS",
    "settings_show_ping": "Arată ping",
    "settings_show_coordinates": "Arată coordonatele",
    "settings_anon_names": "Nume de jucători anonime",
    "settings_hide_emotes": "Ascunde emoturi-le",
    "settings_text_killfeed": "Killfeed bazat pe text",
    "settings_colored_weapon_slots": "Sloturi la arme colorate",
    "settings_scope_looping": "Ciclează selecția de instrumente optice",
    "settings_draw_hud": "Desenează HUD-ul",
    "settings_autopickup": "Activează strângerea automatică",
    "settings_autopickup_dual_guns": "Strîngerea automatică a armelor duble",

    "settings_load_settings": "Încarcă setările",
    "settings_copy_settings": "Copiază setările la clipboard",
    "settings_reset_settings": "Resetează setările",

    "settings_reload": "Reîncarcă",
    "settings_mobile_controls": "Activează controalele de mobil",
    "settings_joystick_size": "Mărimea Joystick-ului",
    "settings_joystick_opacity": "Opacitatea Joystick-ului",

    "loadout_skins": "Skinuri",
    "loadout_emotes": "Emoticoane",
    "loadout_crosshairs": "Crosshairs",
    "loadout_badges": "Insigne",
    "loadout_special": "Special",
    "loadout_crosshairs_default": "System Implicit",
    "loadout_crosshairs_size": "Mărime:",
    "loadout_crosshairs_color": "Culoare:",
    "loadout_crosshairs_stroke_size": "Mărimea Conturului:",
    "loadout_crosshairs_stroke_color": "Culoarea Conturului:",

    "emotes_category_People": "Persoane",
    "emotes_category_Text": "Text",
    "emotes_category_Memes": "Memes",
    "emotes_category_Icons": "Icoane",
    "emotes_category_Misc": "Misc",

    "featured_youtubr": "Youtubr Recomandate",
    "featured_streamr": "Streamr Recomandate",

    "btn_report": "Raportează",
    "btn_spectate_kill_leader": "Spectatează Liderul Ucigaș",
    "btn_spectate": "Spectatează",
    "btn_play_again": "Joacăte Din Nou",
    "btn_menu": "Meniu",

    "msg_waiting_for_leader": "Așteptând Liderul",
    "msg_you_died": "Ai Murit.",
    "msg_player_died": "<player> a murit.",
    "msg_win": "Câștigătorul Câștigătorul Cu Cina De Pui!",
    "msg_your_rank": "Rank",

    "msg_kills": "Ucideri: <kills>",

    "gas_waiting": "Gazul toxic avansează în <time>",
    "gas_advancing": "Gazul toxic avansează! Mișcăte la zona sigură",
    "gas_inactive": "Așteptând jucători...",

    "action_open_door": "Deschide Ușa",
    "action_close_door": "Închide Ușa",
    "action_revive": "Reînvie <player>",
    "action_cancel": "Anulează",
    "action_reloading": "Reîncărcând...",
    "action_reviving": "Reînviind...",
    "action_being_revived": "Fiind reînviat...",
    "action_gauze_use": "Aplicând <item>",
    "action_medikit_use": "Folosind <item>",
    "action_cola_use": "Bând <item>",
    "action_tablets_use": "Luăm <item>",

    "interact_airdrop_crate_locked": "Deschide Airdrop-ul",
    "interact_control_panel": "Activează Panoul De Control",
    "interact_generator": "Activează Generatorul",
    "interact_button": "Apasă Butonul",

    "loading_spritesheets": "Se Încarcă Spritesheets <progress>",
    "loading_connecting": "Connectare",
    "loading_joining_game": "Intrare În Joc",
    "loading_fetching_data": "Primirea Datelor De La Server...",
    "loading_finding_game": "Căutarea Unui Joc",

    "keybind_clear_tooltip": "Ca să scoateți o tastă, apăsați tasta apoi apăsați ori <kbd>Escape</kbd> sau <kbd>Backspace</kbd>.",
    "keybind_reset": "Resetează la inițial",
    "bindings_+up": "Mișcare Sus",
    "bindings_+down": "Mișcare Jos",
    "bindings_+left": "Mișcare Stânga",
    "bindings_+right": "Mișcare Dreapta",
    "bindings_interact": "Interacționează",
    "bindings_loot": "Jefuiește",
    "bindings_slot 0": "Îmbracă Primul",
    "bindings_slot 1": "Îmbracă Al Doilea",
    "bindings_slot 2": "Îmbracă Melee",
    "bindings_equip_or_cycle_throwables 1": "Îmbracă/Ciclează Aruncabil",
    "bindings_last_item": "Îmbracă Ultimul Pistol",
    "bindings_other_weapon": "Îmbracă Acelălant Pistol",
    "bindings_swap_gun_slots": "Schimbă Cu Locul Sloturile La Pistoale",
    "bindings_cycle_items -1": "Îmbracă Arma Precedentă",
    "bindings_cycle_items 1": "Îmbracă Arma Următoare",
    "bindings_+attack": "Folosește Arma",
    "bindings_drop": "Aruncă Arma Curentă",
    "bindings_reload": "Reîncarcă",
    "bindings_cycle_scopes -1": "Instrumentul Optic Precedent",
    "bindings_cycle_scopes 1": "Următorul Instrumentul Optic",
    "bindings_use_consumable gauze": "Folosește Tifonul",
    "bindings_use_consumable medikit": "Folosește Trusa Medicală",
    "bindings_use_consumable cola": "Folosește Cola",
    "bindings_use_consumable tablets": "Folosește Tablete",
    "bindings_cancel_action": "Anulează Acțiunea",
    "bindings_+view_map": "Privește Mapa",
    "bindings_toggle_map": "Schimbă Mapa Completă",
    "bindings_toggle_minimap": "Schimbă Minimapul",
    "bindings_toggle_hud": "Schimbă HUD",
    "bindings_+emote_wheel": "Emoticoane",
    "bindings_+map_ping_wheel": "Schimbă la Ping-ul Mapei",
    "bindings_+map_ping": "Ping-urile Mapei",
    "bindings_toggle_console": "Schimbă Consola",
    "bindings_toggle_slot_lock": "Schimbă Încheierea Sloturilor",

    "kf_suicide_kill": "<player> sa sinucis",
    "kf_suicide_down": "<player> sa dat jos",
    "kf_two_party_kill": "<player> l-a omorăt pe <victim>",
    "kf_two_party_down": "<player> l-a dat jos pe <victim>",
    "kf_bleed_out_kill": "<player> a sîngerat",
    "kf_bleed_out_down": "<player> a sîngerat non-letal",
    "kf_finished_off_kill": "<player> l-a terminat pe <victim>",
    "kf_finished_off_down": "<player> cu grijă la dat jos pe <victim>",
    "kf_finally_died": "<player> în sfârșit a murit",
    "kf_finally_ended_themselves": "<player> în sfârșit sau sinucis",
    "kf_finally_killed": "<player> în sfârșit a fost omorât",
    "kf_finally_down": "<player> în sfârșit a fost dat pe jos",
    "kf_gas_kill": "<player> a murit de la gaz",
    "kf_gas_down": "<player> a fost dat jos de la gaz",
    "kf_airdrop_kill": "<player> a fost brutal strivit de un airdrop",
    "kf_airdrop_down": "<player> a fost violent dat jos de un airdrop",
    "kf_kl_promotion": "<player> a fost promovat ca Lider Ucigaș!",
    "kf_kl_killed": "<player> a omorât Liderul Ucigaș",
    "kf_kl_dead": "Liderul Ucigaș a murit!",
    "kf_kl_suicide": "Liderul Ucigaș s-au sinucis!",

    "tt_restores": "<item> restaulează <amount> <type>",
    "tt_reduces": "<item> reduce <percent>% damage",

    "go_kills": "Ucideri:",
    "go_damage_done": "Daună Făcut:",
    "go_damage_taken": "Daună Primită:",
    "go_time_alive": "Timp Supraviețuit:",

    "create_team": "Crează Echipă",
    "create_team_autofill": "Umplere Automată",
    "create_team_lock": "Închide Echipa",
    "create_team_waiting": "Așteptând...",
    "create_team_play": "Începe Joaca",

    "report_reporting": "Raportând",
    "report_id": "Raport ID:",
    "report_instructions": `
      <p><strong>Vă rugăm citiți instrucțiunile de mai injos!</strong> Dacă nu, raportul dumneavoastră va fi ignorat.</p>
      <h4>Cum să Submiteți un Raport</h4>
      <ol>
        <li>Intrați în <a href="https://discord.suroi.io">serverul de Discord.</a></li>
        <li>Duceți-vă la <a href="https://discord.com/channels/1077043833621184563/1135288369526607973">#cheater-reports
            channel.</a></li>
        <li>Citiți regulile de raport la mesajul fixat.</li>
        <li>Submiteți raportul dumneavoastră ca un mesaj.</li>
      </ol>`,

    "languages": "Limbile",

    // loot

    "gauze": "Tifon",
    "medikit": "Trusă Medicală",
    "cola": "Cola",
    "tablets": "Tablete",

    "basic_vest": "Vestă De Bază",
    "regular_vest": "Vestă Obișnuită",
    "tactical_vest": "Vestă Tacticală",
    "basic_helmet": "Cască De Bază",
    "regular_helmet": "Cască Obișnuită",
    "tactical_helmet": "Cască Tacticală",
    "bag": "Geantă", // This shouldn't show up in game
    "basic_pack": "Geantă De Bază",
    "regular_pack": "Geantă Obișnuită",
    "tactical_pack": "Geantă Tacticală",

    "1x_scope": "1x Optic", // This shouldn't show up in game
    "2x_scope": "2x Optic",
    "4x_scope": "4x Optic",
    "8x_scope": "8x Optic",
    "15x_scope": "15x Optic",

    "fists": "Mâini",
    "baseball_bat": "Bâtă De Baseball",
    "hatchet": "Toporaș",
    "kbar": "K-bar",
    "maul": "Maul",
    "gas_can": "Canistră Cu Gaz",
    "heap_sword": "Sabie HE-AP",
    "steelfang": "Steelfang",
    "ice_pick": "Târnăcop De Gheață",
    "seax": "Seax",
    "crowbar": "Rangă",
    "sickle": "Seceră",

    "mosin": "Mosin-Nagant",
    "radio": "Radio",
    "lewis_gun": "Pușcă Mitralieră Lewis",
    "deathray": "Rază De Moarte",
    "firework_launcher": "Lansator De Artificii",
    "arena_closer": "Destrugător De Lumi",
    "revitalizer": "Revitalizant",
    "s_g17": "G17 (Scoped)",
    "vss": "VSS",
    "pp19": "PP-19",
    "vepr12": "Vepr-12",
    "flues": "Flues",
    "m1895": "M1895",
    "ak47": "AK-47",
    "vector": "Vector",
    "mini14": "Mini-14",
    "model_89": "Model 89",
    "negev": "Negev",
    "tango_51": "Tango 51",
    "barrett": "Barrett M95",
    "stoner_63": "Stoner 63",
    "m1_garand": "M1 Garand",

    "frag_grenade": "Grenadă Fraged",
    "smoke_grenade": "Grenadă De Fum",
    "confetti_grenade": "Grenadă Cu Confetti",

    // For dual guns
    "dual_template": "Dublu <gun>",

    // regions
    "region_dev": "Server Local",
    "region_na": "America De Nord",
    "region_eu": "Europa",
    "region_sa": "America De Sud",
    "region_as": "Asia"
};
